import { createRouter, createWebHistory } from 'vue-router'
import AgendaViewVue from '@/views/AgendaView.vue'
import WerklijstViewVue from '@/views/WerklijstView.vue'
import WerkbonViewVue from '@/views/WerkbonView.vue'
import ExternalObjectsViewVue from '@/views/ExternalObjectsView.vue'
import CameraViewVue from '@/views/CameraView.vue'
import LoginViewVue from '@/views/LoginView.vue'
import { pullTimeClocksFromApi } from '@/services/time-service'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
        path: '/login',
        name: 'login',
        component: LoginViewVue
    },
    {
      path: '/',
      name: 'home',
      component: AgendaViewVue
    },
    {
      path: '/werklijst',
      name: 'werklijst',
      props: true,
      component: WerklijstViewVue
    },
    {
      path: '/werkbon/:id',
      name: 'werkbon',
      props: true,
      component: WerkbonViewVue
    },
    {
        path: '/external-objects/:opdrachtId',
        name: 'external-objects',
        props: true,
        component: ExternalObjectsViewVue
    },
    {
        path:'/camera/:id',
        name: 'camera',
        props: true,
        component: CameraViewVue
    }
    /* {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/AboutView.vue')
    } */
  ]
})

//
router.beforeEach((to, from, next) => {
    pullTimeClocksFromApi();
    
    next();
})

export default router
