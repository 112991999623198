<script setup>
import { RouterView } from 'vue-router'
import PreloaderComponent from './components/PreloaderComponent.vue';
import { useLoadingStore } from './stores/loadingStore';

const loadingStore = useLoadingStore();
</script>

<template>
    <PreloaderComponent v-if="loadingStore.loading"/>
  <RouterView />
</template>
