import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTranslationStore = defineStore('translation', () => {
    //get language from local storage user.language
    const language = localStorage.getItem('language');
    const lang = ref(language ?? 'nl');
    //const lang = ref('nl');

    const translations = {
        'nl': {
            // Algemeen
            'algemeen.data_ophalen': 'Data ophalen',
            'algemeen.no_connection': 'De applicatie is momenteel offline, je werkt op een cache. De applicatie zal automatisch synchroniseren wanneer de verbinding hersteld is.',

            //menu
            'menu.agenda': 'Agenda',
            'menu.afmelden': 'Afmelden',

            // TimeClocks
            'timeclocks.vertrek_depot': 'Vertrek depot',
            'timeclocks.aankomst_werf': 'Aankomst werf',
            'timeclocks.vertrek_werf': 'Vertrek werf',
            'timeclocks.aankomst_depot': 'Terug depot',
            'timeclocks.niet_ingesteld': 'Niet ingesteld',

            // Agenda pagina
            'agenda.titel': 'Agenda',
            'agenda.welkom': 'Welkom terug, $user dit zijn je opdrachten',
            'agenda.planning_opmerkingen': 'Planning opmerkingen',
            'agenda.voertuig': 'Voertuig',
            'agenda.bestuurder': 'Bestuurder',
            'agenda.begeleider': 'Begeleider',
            'agenda.aantal_opdrachten': 'Aantal opdrachten',
            'agenda.planning_opmerking.opmerking': 'Opmerking',

            // Werklijst pagina
            'werklijst.titel': 'Werklijst',
            'werklijst.volgnr': 'Volgnr',
            'werklijst.naam_klant': 'Naam Klant',
            'werklijst.contact': 'Contact',
            'werklijst.adres': 'Adres',
            'werklijst.telefoon_klant': 'Telefoon Klant',
            'werklijst.telefoon_contact': 'Telefoon Contact',
            'werklijst.werkbon': 'Werkbon',
            'werklijst.voorkeurstijdstip': 'Voorkeurstijdstip',
            'werklijst.status': 'Status',
            'werklijst.bestanden': 'Bestanden',
            'werklijst.tijdstippen': 'Tijdstippen',
            'werklijst.terug_knop': 'Terug Knop',

            'werklijst_item.bestanden': 'Bestanden',
            'werklijst_item.omschrijving': 'Omschrijving',
            'werklijst_item.opdracht': 'Opdracht',

            // Werkbon pagina
            'werkbon.titel': 'Werkbon',
            'werkbon.handtekening_button': 'Handtekening',
            'werkbon.opdrachtbon_button': 'Opdrachtbon',
            'werkbon.voorlopige_opdrachtbon_button': 'Voorlopige Opdrachtbon',
            'werkbon.bijlagen': 'Bijlagen',
            'werkbon.klant': 'Klant',
            'werkbon.werfadres': 'Werfadres',
            'werkbon.contact': 'Contact',
            'werkbon.contact_telefoon': 'Contact Telefoon',
            'werkbon.facturatie': 'Facturatie',
            'werkbon.factuuradres': 'Factuuradres',
            'werkbon.btw': 'BTW Nummer',
            'werkbon.opdracht': 'Opdracht',
            'werkbon.opmerking': 'Opmerking',
            'werkbon.actie': 'Actie',
            'werkbon.aantal': 'Aantal',
            'werkbon.unit': 'Eenheid',
            'werkbon.eenheidsprijs': 'Eenheidsprijs',
            'werkbon.subtotaal': 'Subtotaal',
            'werkbon.terug_naar_werklijst': 'Terug naar werklijst',
            
            // WerkbonModal
            'werkbon.modal.btw_regeling': '6% BTW regeling',
            'werkbon.modal.ik_ondergetekende': 'Ik ondergetekende verklaar, dat de woning/pand niet ouder is dan 10 jaar.',
            'werkbon.modal.ja': 'Ja',
            'werkbon.modal.nee': 'Nee',
            'werkbon.modal.niet_van_toepassing': 'Niet van toepassing',
            'werkbon.modal.niet_geselecteerd': 'Niet geselecteerd',
            'werkbon.modal.opmerking': 'Opmerking',
            'werkbon.modal.opmerking_placeholder': 'Hier komt de opmerking van de klant',
            'werkbon.modal.opmerking_klant': 'Opmerking klant',
            'werkbon.modal.opmerking_klant_placeholder': 'Hier komt de opmerking van de klant',
            'werkbon.modal.naam': 'Naam',
            'werkbon.modal.naam_placeholder': 'Naam van de persoon die ondertekend',
            'werkbon.modal.status': 'Status',
            'werkbon.modal.email': 'Email',
            'werkbon.modal.email_placeholder': 'Email adres van de persoon die ondertekend',
            'werkbon.modal.handtekening': 'Handtekening',
            'werkbon.modal.te_ontvangen': 'Te ontvangen',
            'werkbon.modal.bedrag_ontvangen': 'Bedrag ontvangen',
            'werkbon.modal.status_opdracht': 'Status opdracht',
            'werkbon.modal.reden_niet_uitgevoerd': 'Reden niet uitgevoerd',
            'werkbon.modal.selecteer_reden_niet_uitgevoerd': 'Selecteer reden niet uitgevoerd',
            'werkbon.modal.andere_reden': 'Andere reden',
            'werkbon.modal.andere_reden_placeholder': 'Als de reden niet vermeld staat hierboven',
            'werkbon.modal.vertrektijd_werf': 'Vertrek depot',
            'werkbon.modal.aankomst': 'Aankomst',
            'werkbon.modal.cancel_en_sluiten': 'Cancel en sluiten',
            'werkbon.modal.opslaan_en_sluiten': 'Opslaan en sluiten',
            'werkbon.modal.afsluiten_en_verzenden': 'Afsluiten en verzenden',
            'werkbon.modal.aan_het_versturen': 'Aan het versturen...',
            'werkbon.modal.selecteer_status': 'Selecteer status',

            // Externe objecten
            'eo.titel': 'Externe objecten',
            'eo.geen_omschrijving': 'Geen omschrijving',
            'eo.foto_uploaden': 'Foto uploaden',
            'eo.foto_toevoegen': 'Foto toevoegen',
            'eo.terug_button': 'Terug',

            // Externe objecten modal
            'eo.delete_confirm': 'Weet je zeker dat je dit bestand wilt verwijderen?',
            'eo.foto': 'Foto',
            'eo.beschrijving': 'Beschrijving',
            'eo.gemaakt_op': 'Gemaakt op',
            'eo.gemaakt_door': 'Gemaakt door',
            'eo.volgorde': 'Volgorde',
            'eo.opslaan': 'Opslaan',
            'eo.cancel': 'Cancel',
            'eo.delete': 'Delete',
            'eo.geen_verbinding': 'Geen verbinding',

        },
        'fr': {
            // General
            'algemeen.data_ophalen': 'Récupération des données',
            'algemeen.no_connection': 'L\'application est actuellement hors ligne, vous travaillez sur un cache. L\'application se synchronisera automatiquement lorsque la connexion sera rétablie.',
        
            // Menu
            'menu.agenda': 'Agenda',
            'menu.afmelden': 'Se déconnecter',
        
            // Horloges de temps
            'timeclocks.vertrek_depot': 'Départ du dépôt',
            'timeclocks.aankomst_werf': 'Arrivée sur le site',
            'timeclocks.vertrek_werf': 'Départ du site',
            'timeclocks.aankomst_depot': 'Retour au dépôt',
            'timeclocks.niet_ingesteld': 'Non configuré',
        
            // Page de l'agenda
            'agenda.titel': 'Agenda',
            'agenda.welkom': 'Bienvenue, $user voici vos missions',
            'agenda.planning_opmerkingen': 'Remarques de planification',
            'agenda.voertuig': 'Véhicule',
            'agenda.bestuurder': 'Conducteur',
            'agenda.begeleider': 'Accompagnateur',
            'agenda.aantal_opdrachten': 'Missions',
            'agenda.planning_opmerking.opmerking': 'Remarque',
        
            // Page de la liste de travail
            'werklijst.titel': 'Liste de travail',
            'werklijst.volgnr': 'Numéro de suivi',
            'werklijst.naam_klant': 'Nom du client',
            'werklijst.contact': 'Contact',
            'werklijst.adres': 'Adresse',
            'werklijst.telefoon_klant': 'Téléphone du client',
            'werklijst.telefoon_contact': 'Téléphone de contact',
            'werklijst.werkbon': 'Bon de travail',
            'werklijst.voorkeurstijdstip': 'Créneau préféré',
            'werklijst.status': 'Statut',
            'werklijst.bestanden': 'Fichiers',
            'werklijst.tijdstippen': 'Horaires',
            'werklijst.terug_knop': 'Retour',
        
            'werklijst_item.bestanden': 'Fichiers',
            'werklijst_item.omschrijving': 'Description',
            'werklijst_item.opdracht': 'Mission',
        
            // Page du bon de travail
            'werkbon.titel': 'Bon de travail',
            'werkbon.handtekening_button': 'Signature',
            'werkbon.opdrachtbon_button': 'Bon de commande',
            'werkbon.voorlopige_opdrachtbon_button': 'Bon de commande provisoire',
            'werkbon.bijlagen': 'Pièces jointes',
            'werkbon.klant': 'Client',
            'werkbon.werfadres': 'Adresse du chantier',
            'werkbon.contact': 'Contact',
            'werkbon.contact_telefoon': 'Téléphone du contact',
            'werkbon.facturatie': 'Facturation',
            'werkbon.factuuradres': 'Adresse de facturation',
            'werkbon.btw': 'Numéro TVA',
            'werkbon.opdracht': 'Mission',
            'werkbon.opmerking': 'Remarque',
            'werkbon.actie': 'Action',
            'werkbon.aantal': 'Quantité',
            'werkbon.unit': 'Unité',
            'werkbon.eenheidsprijs': 'Prix unitaire',
            'werkbon.subtotaal': 'Sous-total',
            'werkbon.terug_naar_werklijst': 'Retour à la liste de travail',
        
            // Modal du bon de travail
            'werkbon.modal.btw_regeling': 'Régime TVA 6%',
            'werkbon.modal.ik_ondergetekende': 'Je soussigné déclare que le bâtiment n\'a pas plus de 10 ans.',
            'werkbon.modal.ja': 'Oui',
            'werkbon.modal.nee': 'Non',
            'werkbon.modal.niet_van_toepassing': 'Non applicable',
            'werkbon.modal.niet_geselecteerd': 'Non sélectionné',
            'werkbon.modal.opmerking': 'Remarque',
            'werkbon.modal.opmerking_placeholder': 'Voici la remarque du client',
            'werkbon.modal.opmerking_klant': 'Remarque du client',
            'werkbon.modal.opmerking_klant_placeholder': 'Voici la remarque du client',
            'werkbon.modal.naam': 'Nom',
            'werkbon.modal.naam_placeholder': 'Nom de la personne qui signe',
            'werkbon.modal.status': 'Statut',
            'werkbon.modal.email': 'Email',
            'werkbon.modal.email_placeholder': 'Adresse email de la personne qui signe',
            'werkbon.modal.handtekening': 'Signature',
            'werkbon.modal.te_ontvangen': 'À recevoir',
            'werkbon.modal.bedrag_ontvangen': 'Montant reçu',
            'werkbon.modal.status_opdracht': 'Statut de la mission',
            'werkbon.modal.reden_niet_uitgevoerd': 'Raison de non-exécution',
            'werkbon.modal.selecteer_reden_niet_uitgevoerd': 'Sélectionnez la raison de non-exécution',
            'werkbon.modal.andere_reden': 'Autre raison',
            'werkbon.modal.andere_reden_placeholder': 'Si la raison n\'est pas mentionnée ci-dessus',
            'werkbon.modal.vertrektijd_werf': 'Départ du dépôt',
            'werkbon.modal.aankomst': 'Arrivée',
            'werkbon.modal.cancel_en_sluiten': 'Annuler et fermer',
            'werkbon.modal.opslaan_en_sluiten': 'Sauvegarder et fermer',
            'werkbon.modal.afsluiten_en_verzenden': 'Finaliser et envoyer',
            'werkbon.modal.aan_het_versturen': 'Envoi en cours...',
            'werkbon.modal.selecteer_status': 'Sélectionnez le statut',
        
            // Objets externes
            'eo.titel': 'Objets externes',
            'eo.geen_omschrijving': 'Pas de description',
            'eo.foto_uploaden': 'Télécharger une photo',
            'eo.foto_toevoegen': 'Ajouter une photo',
            'eo.terug_button': 'Retour',
        
            // Modal des objets externes
            'eo.delete_confirm': 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
            'eo.foto': 'Photo',
            'eo.beschrijving': 'Description',
            'eo.gemaakt_op': 'Créé le',
            'eo.gemaakt_door': 'Créé par',
            'eo.volgorde': 'Ordre',
            'eo.opslaan': 'Sauvegarder',
            'eo.cancel': 'Annuler',
            'eo.delete': 'Supprimer',
            'eo.geen_verbinding': 'Pas de connexion'
        }
    }

    const t = (key, params = {}) => {
        
        try {
            const translation = translations[lang.value][key]
            return translation.replace(/\$([a-z]+)/g, (match, p1) => {
                return params[p1]
            })
        } catch (e) {
            console.error(`Translation for key ${key} not found`)
            return '__' + key + '__'
        }
    }

    return {
        translations,
        t,
        lang
    }
})