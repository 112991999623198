<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
//import { useWerkbonModalStore } from '@/stores/werkbonModalStore';
import { useExternalObjectsModalStore } from '@/stores/externalobjectModalStore';
import { useApiStore } from '@/stores/apiStore';
import { onMounted, computed, reactive, watch } from 'vue'
import { newUpdateApiExternalObject, newDeleteApiExternalObject } from '@/services/external-object-service';
import dayjs from 'dayjs';
import { useTranslationStore } from '@/stores/translationStore';

//const werkbonModalStore = useWerkbonModalStore();
const apiStore = useApiStore();
const externalObjectsModalStore = useExternalObjectsModalStore();
const translationStore = useTranslationStore();

const props = defineProps(['opdrachtId', 'externalObject']);

const updateForm = reactive({
    opdrachtId: props.opdrachtId,
    eoid: '',
    description: '',
    eovolgorde: ''
})

const deleteForm = reactive({
    opdrachtId: props.opdrachtId,
    eoid: ''
})

onMounted(() => {
    console.log(externalObjectsModalStore.newModalExternalObject);
});

const updateExternalObject = async () => {
    if(!apiStore.connection) return;
    await newUpdateApiExternalObject(updateForm);
    await externalObjectsModalStore.updateEoObjects(props.opdrachtId)

    updateForm.value = {
        description: '',
        eoid: ''
    }
    externalObjectsModalStore.updateModal = false;

}

const deleteExternalObject = async () => {
    if(!apiStore.connection) return;
    deleteForm.eoid = externalObjectsModalStore.modalExternalObject.eoid;
    if(confirm(translationStore.t('eo.delete_confirm')))
        await newDeleteApiExternalObject(deleteForm);
    else
        return;
    await externalObjectsModalStore.updateEoObjects(props.opdrachtId)

    deleteForm.value = {
        eoid: ''
    }
    externalObjectsModalStore.updateModal = false;
}

watch (
    () => externalObjectsModalStore.modalExternalObject,
    (modalExternalObject) => {
        updateForm.description = modalExternalObject.eoomschrijving;
        updateForm.eoid = modalExternalObject.eoid;
        updateForm.eovolgorde = modalExternalObject.eovolgorde;
    }
    
)

const downloadBase64File = async () => {
    let base64String;
    let linkSource;
    console.log('extension', externalObjectsModalStore.modalExternalObject.extension)
    if(externalObjectsModalStore.modalExternalObject.extension.includes('pdf')) {
        base64String = 'data:application/pdf;base64,' + externalObjectsModalStore.modalExternalObject.eobase64;
        linkSource = base64String;
    } else {
        linkSource = externalObjectsModalStore.modalExternalObject.image
    }
    
    const downloadLink = document.createElement("a");
    const fileName = externalObjectsModalStore.modalExternalObject.eofile;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

const image = computed(() => {
    //base64 decode image and return it as url that cna be used in image src
    //get file extension from eoFile property
    console.log(externalObjectsModalStore.modalExternalObject)
    const extension = externalObjectsModalStore.modalExternalObject.eofile.split('.').pop().toLowerCase();
    

    if(extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'bmp')
        return 'data:image/jpg;base64,' + externalObjectsModalStore.modalExternalObject.eobase64;
    if(extension === 'docx')
        return '/docx_placeholder.png';
    if(extension === 'pdf')
        return '/pdf_placeholder.png';
    
    return 'https://via.placeholder.com/150';
});

</script>

<template>
    <TransitionRoot as="template" :show="externalObjectsModalStore.updateModal">
        <Dialog as="div" class="relative z-[10000]" @close="externalObjectsModalStore.updateModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-[10000] w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-mc-primary text-left shadow-xl transition-all sm:my-8 sm:w-full mx-12">
                <button @click="externalObjectsModalStore.updateModal = false" class="right-5 top-5 absolute outline-none">
                    <XMarkIcon class="h-10 w-10 text-white" aria-hidden="true" />
                </button>
                <div class="flex flex-col text-white w-full gap-2 pt-10">
                    <div class="px-6 flex gap-4">
                        <div class="flex flex-col gap-3 w-full">
                            <h3>{{ translationStore.t('eo.foto') }}</h3>
                            <div class="bg-mc-secondary w-full rounded-lg" @click="downloadBase64File">
                                <img :src="image" alt="" class="h-64 object-contain mx-auto">
                            </div>
                            
                        </div>
                        <div class="flex flex-col gap-3 w-full">
                            <h3>{{ translationStore.t('eo.beschrijving') }}</h3>
                            <textarea cols="30" rows="10" class="rounded-lg maxi-input" v-model="updateForm.description"></textarea>
                        </div>
                    </div>
                    <div class="p-6 flex gap-4">
                        <div class="flex w-full items-end">
                            <div class="w-full">
                                <h3>{{ translationStore.t('eo.gemaakt_op') }}</h3>
                                <span>
                                    {{ dayjs(externalObjectsModalStore.modalExternalObject.created).format('DD/MM/YYYY') }}
                                </span>
                            </div>
                            <div class="w-full">
                                <h3>{{ translationStore.t('eo.gemaakt_door') }}</h3>
                                <span>
                                    {{ externalObjectsModalStore.modalExternalObject.createdby }}
                                </span>
                            </div>
                            <div class="w-full">
                                <h3>{{ translationStore.t('eo.volgorde') }}</h3>
                                <input type="number" class="rounded-lg maxi-input" v-model="updateForm.eovolgorde" />
                            </div>
                        </div>
                        <div class="w-full flex gap-2">
                            <button class="w-full maxi-button" @click="externalObjectsModalStore.updateModal = false">
                                {{ translationStore.t('eo.cancel') }}
                            </button>
                            <button class="w-full maxi-button" @click="deleteExternalObject()">
                                {{ translationStore.t('eo.delete') }}
                            </button>
                            <button class="w-full maxi-button" :disabled="!apiStore.connection" :class="[apiStore.connection ? '' : 'disabled-button']" @click="updateExternalObject()">
                                {{ apiStore.connection ? translationStore.t('eo.opslaan') : translationStore.t('eo.geen_verbinding') }}
                            </button>
                        </div>
                        
                    </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>