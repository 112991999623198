import axios from 'axios';
import { testFunction } from './api-service';

const api = 'https://radis.maxicleaning.be/api/app/rma';
//const api = 'http://localhost:3000/api/app/rma';

export const login = async (username, password) => {
    console.log('Login function');
    try {
        const { data, status } = await axios.post(`${api}/login`, { username, password });

        console.log('login', data);
        if (status === 200 && data.user) {
            localStorage.setItem('user', JSON.stringify(data.user));
            localStorage.setItem('language', data.user.language.toLowerCase())
            //localStorage.setItem('language', 'fr')
            /* setTimeout(() => {
                testFunction();
            }, 2000); */
        } else {
            alert('Invalid credentials');
        }
        return data;
    } catch (error) {
        console.error(error);
        alert('Ongeldige login of wachtwoord');
        return null;
    }
};
