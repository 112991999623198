
//"2023-12-27T14:30:00.000Z"
//only return hours and minutes
//use dayjs for this

export const timestampToTime = (timestamp) => {
    if(timestamp === null) {
        return null;
    }

    try {
        const hours = timestamp.split('T')[1].split(':')[0];
        const minutes = timestamp.split('T')[1].split(':')[1];
        //console.log('timestampToTime', hours + ':' + minutes);
        return hours + ':' + minutes;
    
    } catch (error) {
        console.log('errortimestampToTime', timestamp);
        return timestamp;
    }

}