import Dexie from 'dexie';

class GenCodeClass {
    constructor(code, codeGroup, value) {
        this.code = code;
        this.codeGroup = codeGroup;
        this.value = value;
    }
}


export const db = new Dexie('myDatabase');
db.version(10).stores({
    cache: '++id, type, data', // Primary key only
    lastSync: '++id, lastSync',
    werkbonForm: '++id, opdrachtId, werkbonForm',
    putTimes: '++id, opdrachtId, vertrek, aankomstWerf, vertrekWerf, aankomst',
    timeClock: '++id, opdrachtId, type, time, synced',
    externalObjects: '++id, compoundIndex, opdrachtid, EOId, EOBase64, data, referenceid',

    //v2
    agendaItems: '++id, begeleiderId, bestuurderId, typeWagen, datumUitvoering',
    worklists: '++id, opdrachtId',
    orders: '++id, opdrachtId',
    orderDetails: '++id, opdrachtId',
});

export const newDb = new Dexie('newDatabase');
newDb.version(3).stores({
    agendaItems: '++id, begeleiderId, bestuurderId, typeWagen, datumUitvoering',
    orders: '++id, opdrachtId, begeleiderId, bestuurderId, typeWagen, datumUitvoering',
    werkbonForm: '++id, opdrachtId, werkbonForm',
});

export const staticdb = new Dexie('staticDatabase');
staticdb.version(5).stores({
    genCodes: '++id, Code, CodeGroup, Value',
});


staticdb.genCodes.mapToClass(GenCodeClass);
