<script setup>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { ClockIcon } from '@heroicons/vue/24/outline';
import dayjs from 'dayjs';
import { computed, onMounted, ref } from 'vue';
//import { storePutTimes, loadPutTimes } from '@/functions/localCache';
import { getLocalTimeClocks, updateApiTimeClock } from '../services/time-service';
import * as v2localCache from '@/services/local-service';
import { v2putTimeClock } from '@/services/api-service';
import { useTranslationStore } from '@/stores/translationStore';

const translationStore = useTranslationStore();

const props = defineProps(['label', 'position', 'round', 'keyValue', 'opdrachtId', 'readonly'])

const statusBorder = ref('');

const oldTime = ref({
    hh: "00",
    mm: "00"
});

const vertrek = ref({
    hh: "00",
    mm: "00"
});

const aankomstWerf = ref({
    hh: "00",
    mm: "00"
});

const vertrekWerf = ref({
    hh: "00",
    mm: "00"
});

const aankomst = ref({
    hh: "00",
    mm: "00"
});

const yourTimeValue = ref({
    hh: "00",
    mm: "00"
});

const hours = [
    '00',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
];

const minutes = [
    '00',
    '15',
    '30',
    '45',
];

const selectedTime = computed(() => {
    return yourTimeValue.value.hh + ':' + yourTimeValue.value.mm
})

const captureOldTime = () => {
    console.log('captureOldTime', yourTimeValue.value.hh, yourTimeValue.value.mm)
    oldTime.value = {
        hh: yourTimeValue.value.hh,
        mm: yourTimeValue.value.mm
    }
}


const setTime = () => {
    let hour = parseInt(dayjs().format('HH'));
    let minute = parseInt(dayjs().format('mm'));

    const roundMinute = (min, roundType) => {
        switch (roundType) {
            case 'down':
                return Math.floor(min / 15) * 15;
            case 'up':
                return Math.ceil(min / 15) * 15;
            default: // 'round' or other values
                return Math.round(min / 15) * 15;
        }
    };

    minute = roundMinute(minute, props.round);

    // Adjust hour if minutes overflow
    if (minute >= 60) {
        minute -= 60;
        hour += 1;
    }

    // Handle hour overflow
    if (hour >= 24) {
        hour = 0;
    }

    yourTimeValue.value = {
        hh: hour.toString().padStart(2, '0'),
        mm: minute.toString().padStart(2, '0')
    };

    updateApi();
};



const getInitialTime = async () => {
    const localTimes = await v2localCache.getLocalTimeClocks(props.opdrachtId);

    const parseTime = (timeString) => ({
        hh: timeString?.split(':')[0] || '00',
        mm: timeString?.split(':')[1] || '00'
    });

    if (localTimes && localTimes[props.keyValue]) {
        vertrek.value = parseTime(localTimes.vertrek);
        aankomstWerf.value = parseTime(localTimes.aankomstWerf);
        vertrekWerf.value = parseTime(localTimes.vertrekWerf);
        aankomst.value = parseTime(localTimes.aankomst);
        yourTimeValue.value = parseTime(localTimes[props.keyValue]);

        captureOldTime();
    }
};

getInitialTime();
onMounted(() => {
    console.log('onMounted', props.opdrachtId)
})

const opdrachtId = computed(() => props.opdrachtId);


const updateApi = async () => {
    const apiObject = {
        opdrachtId: opdrachtId.value,
        type: props.keyValue,
        time: selectedTime.value
    }

    const success = await v2putTimeClock(apiObject);

    if(!success) {
            console.log('oldTime', oldTime.value)
            yourTimeValue.value = {
                hh: oldTime.value.hh,
                mm: oldTime.value.mm
            }
            statusBorder.value = 'border-red-600 bg-red-100 duration-300';
        } else {
            statusBorder.value = 'border-green-500 bg-green-100 duration-300';
        }
        setTimeout(() => {
            statusBorder.value = 'duration-300';
    }, 2000)

    /* try {
        if (opdrachtId.value === undefined) {
            console.error('opdrachtId is undefined');
            return;
        }
        const success = await updateApiTimeClock(opdrachtId.value || 0, apiObject);
        
    } catch (error) {
        console.error('error', error)
    } */
    
    return
}

</script>


<template>
    <div class="w-full ">
        <label v-if="label && position != 'left'" class="text-sm min-w-fit">{{ label }}</label>
        <div class="w-full  gap-2" :class="[position == 'left' ? 'grid grid-cols-12 py-1 items-center' : 'flex items-center']">
        <label v-if="label && position == 'left'" class="text-sm min-w-fit col-span-4 text-right">{{ label }}</label>
        <Popover v-if="!readonly" class="relative maxi-input border-2 " :class="[position == 'left' ? 'col-span-6' : '', statusBorder]" v-slot="{ open }">
            <PopoverButton class="w-full text-left" :class="[selectedTime == '00:00' && 'text-gray-400']">{{ selectedTime == '00:00' ? translationStore.t('timeclocks.niet_ingesteld') : selectedTime }}</PopoverButton>


            <PopoverPanel v-if="open" class="absolute z-[1000000] bg-white border border-mc-secondary w-full rounded left-0 h-60" v-slot="{ close }">
    <div class="h-full overflow-auto">
        <div class="grid grid-cols-2 text-center divide-x">
            <div class="flex flex-col divide-y">
                <span class="text-xs font-medium text-gray-400">Uren</span>
                <button v-for="hour in hours" :key="hour"
                    :class="[
                        hour == yourTimeValue.hh ? 'bg-mc-primary text-white' : 'text-gray-800',
                        'py-1.5 hover:bg-mc-primary hover:text-white',
                    ]"
                    @click="yourTimeValue.hh = hour; updateApi();"
                >
                    {{ hour }}
                </button>
            </div>
            <div class="flex flex-col divide-y">
                <span class="text-xs font-medium text-gray-400">Minuten</span>
                <button v-for="minute in minutes" :key="minute"
                    :class="[
                        minute == yourTimeValue.mm ? 'bg-mc-primary text-white' : 'text-gray-800',
                        'py-1.5 hover:bg-mc-primary hover:text-white'
                    ]"
                    @click="yourTimeValue.mm = minute; close(); updateApi();"
                >
                    {{ minute }}
                </button>
            </div>
        </div>
    </div>
</PopoverPanel>

        </Popover>
        <input v-else class="maxi-input border-2 text-gray-600" :class="[position == 'left' ? 'col-span-6' : '']" :value="selectedTime == '00:00' ? 'Niet ingesteld' : selectedTime" readonly disabled>
        <button v-if="!readonly" @click="setTime" class="maxi-button h-full flex flex-col items-center w-full flex-1"  :class="[position == 'left' ? 'col-span-2' : '']">
            <ClockIcon class="h-5 w-5 text-white" aria-hidden="true" />
        </button>
    </div>
    </div>
    
</template>