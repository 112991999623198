import { useDateStore } from "@/stores/dateStore";
import axios from "axios";
import { getLocalTimeClocks, storeLocalAgendaItems, storeLocalGenCodes, storeLocalOpdrachten, updateLocalOpdracht } from './local-service';
import { useApiStore } from "@/stores/apiStore";
import { useWerkbonModalStore } from "@/stores/werkbonModalStore";
import { CronJob } from 'cron';
import dayjs from "dayjs";
import { putTimes } from '@/functions/apiCalls';

const api = 'https://radis.maxicleaning.be/api/app/rma';

const agendaEndpoint = api + '/agenda-by-employee-id-and-date';
const worklistEndpoint = api + '/worklist';
const orderEndpoint = api + '/order-by-id';
const orderDetailsEndpoint = api + '/order-details-by-order-id';
const getGencodesEndpoint = api + '/gen-codes';
const putTimesEndpoint = api + '/order-time-registration';

CronJob.from({
	cronTime: '*/5 * * * *',
	onTick: function () {
		console.log('You will see this message every second');
        const apiStore = useApiStore();
        console.log('apiStore', apiStore.connection);
        if(apiStore.connection)
            testFunction();
	},
	start: true,
	timeZone: 'America/Los_Angeles'
});



export const testFunction = async () => {
    const apiStore = useApiStore();
    if(apiStore.v2loadingInProgress) return;
    apiStore.v2loadingInProgress = true;

    console.log('testFunction');
    const genCodes = await getGenCodes();
    storeLocalGenCodes(genCodes.genCodes);
    const user = await localStorage.getItem('user');
    const userId = JSON.parse(user).werknemerId;

    const agendaItems = await getAgendaItems(userId);

    let opdrachten = [];
    for(let i = 0; i < agendaItems.length; i++) {
        const item = agendaItems[i];
        const worklist = await getWorklist(item.datumUitvoering, item.typeWagen, item.bestuurderId, item.begeleiderId);
        //console.log('worklist ' + i, agendaItems[i] ,worklist);
        for(let j = 0; j < worklist.orders.length; j++) {
            const order = worklist.orders[j];
            let orderData = await getOrder(order.opdrachtId);
            const orderDetails = await getOrderDetails(order.opdrachtId);
            orderData = {...orderData, ...order};
            orderData['details'] = orderDetails.orderDetailItems;
            opdrachten.push(orderData);
        }
    }
    storeLocalAgendaItems(agendaItems);
    storeLocalOpdrachten(opdrachten);

    apiStore.v2loadAgendaItems();
    if(localStorage.getItem('worklistFilters')) {
        console.log('worklistFilters', JSON.parse(localStorage.getItem('worklistFilters')));
        const { date, typeWagen, bestuurderId, begeleiderId } = JSON.parse(localStorage.getItem('worklistFilters'));
        apiStore.v2loadOpdrachten(date, typeWagen, bestuurderId, begeleiderId);

        if(apiStore.v2selectedOpdracht.opdrachtId)
        apiStore.v2loadOpdracht(apiStore.v2selectedOpdracht.opdrachtId);
    }


    apiStore.v2updateLatestSync();
    apiStore.v2loadLatestSync();
    apiStore.v2loadGencodes();
    apiStore.v2loadingInProgress = false;

}

export const getAgendaItems = async (werknemerId) => {
    try {
            const dateStore = useDateStore();
        
            const yesterday = await axios.get(agendaEndpoint + '?Werknemerid='+werknemerId+'&RefDatum='+ dateStore.refDatumYesterday);
            const today = await axios.get(agendaEndpoint + '?Werknemerid='+werknemerId+'&RefDatum='+ dateStore.refDatum);
        
            const items = yesterday?.data.agendaItems.concat(today?.data.agendaItems);

            return items;
    } catch (error) {
        console.error(error);
    }
}

export const getWorklist = async (Dt, TypeWagen, BestuurderID, BegeleiderID) => {
    try {
        if(Dt === null || TypeWagen === null) return;

        //console.log('BegeleiderID', BegeleiderID);
        let response;
        if(BegeleiderID === null)
            response = await axios.get(worklistEndpoint + '?date=' + Dt + '&typeWagen=' + TypeWagen + '&bestuurderId=' + BestuurderID);
        else
            response = await axios.get(worklistEndpoint + '?date=' + Dt + '&typeWagen=' + TypeWagen + '&bestuurderId=' + BestuurderID + '&begeleiderId=' + BegeleiderID);

        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getOrder = async (orderId) => {
    try {
        const response = await axios.get(orderEndpoint + '/' + orderId + '?t=' + new Date().getTime());
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getOrderDetails = async (orderId) => {
    try {
        const response = await axios.get(orderDetailsEndpoint + '/' + orderId + '?t=' + new Date().getTime());
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const getFullOrder = async (orderId) => {
    try {
        const order = await getOrder(orderId);
        const orderDetails = await getOrderDetails(orderId);
        order['details'] = orderDetails.orderDetailItems;
        return order;
    } catch (error) {
        console.error(error);
    }
}

export const getGenCodes = async () => {
    try {
        const response = await axios.get(getGencodesEndpoint);
        console.log('genCodes', response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const v2putWerkbonForm = async (opdrachtId, data, temp = false) => {
    const werkbonModalStore = useWerkbonModalStore();
    let hasError = 0;
    werkbonModalStore.errors = {};

    console.log('data', data);
    console.log('werkbonModalStore', werkbonModalStore);

    if (!temp) {
        if (data.bedragOntvangen < data.teOntvangen) {
            if (!confirm('Het bedrag ontvangen is lager dan het bedrag te ontvangen. Wil je toch opslaan?')) {
                hasError++;
            }
        }

        if (!['Niemand aanwezig', 'Geweigerd'].includes(data.status) && (!data.naam || data.naam.trim() === '')) {
            werkbonModalStore.errors.naam = 'Naam is verplicht';
            hasError++;
        }

        if (!data.status) {
            werkbonModalStore.errors.status = 'Status is verplicht';
            hasError++;
        }

        if (data.bedragOntvangen === null || data.bedragOntvangen === undefined || data.bedragOntvangen === '') {
            werkbonModalStore.errors.bedragOntvangen = 'Bedrag ontvangen is verplicht';
            hasError++;
        }

        if (data.redenNietUitgevoerd === 'andere' && (!data.andereReden || data.andereReden.trim() === '')) {
            werkbonModalStore.errors.andereReden = 'Andere reden is verplicht';
            hasError++;
        }

        if (!data.status) {
            werkbonModalStore.errors.status = 'Status is verplicht';
            hasError++;
        }

        if (!data.email) {
            werkbonModalStore.errors.email = 'Email is verplicht';
            hasError++;
        }

        if (['Door derde', 'Onder voorbehoud', 'Ondertekend'].includes(data.status) && (!data.handtekening || data.handtekening.trim() === '')) {
            werkbonModalStore.errors.handtekeningField = 'Handtekening is verplicht';
            hasError++;
        }

        if (!data.statusOpdracht) {
            werkbonModalStore.errors.statusOpdracht = 'Status opdracht is verplicht';
            hasError++;
        }

        if (data.statusOpdracht === 'Niet uitgevoerd' && (!data.redenNietUitgevoerd || data.redenNietUitgevoerd.trim() === '')) {
            werkbonModalStore.errors.redenNietUitgevoerd = 'Reden niet uitgevoerd is verplicht';
            hasError++;
        }
    }

    console.log('werkbonModalErrors', werkbonModalStore.errors);

    if (hasError > 0) {
        werkbonModalStore.processing = false;
        return 'NOK';
    }

    const putObject = {
        opdrachtId: opdrachtId,
        swtRMA: true,
        handtekeningNaam: data.naam,
        handtekeningStatus: data.status,
        swtOntvangen: true,
        bedragTeOntvangen: data.teOntvangen,
        bedragOntvangen: data.bedragOntvangen,
        attestStatus: data.attestStatus,
        attestType: '',
        statusUitvoeringOpdracht: temp ? null : data.statusOpdracht,
        opmerkingUitvoering: data.opmerking,
        opmerkingKlant: data.opmerkingKlant,
        rmaEmail: data.email,
        swtFactGegsUpdated: false,
        redenNietUitvoering: data.redenNietUitgevoerd,
        redenNietUitvoeringInfo: data.andereReden
    };

    console.log('putObject', putObject);
    const response = await axios.put(`${api}/order`, putObject);

    console.log('finalPut', putObject);
    return response.data;
};

export const v2putTimeClock = async (item) => {
    const nullTime = '1900-01-01T00:00:00.000Z';

    try {
        const formatTime = (time) => {
            if (!time || time === '00:00') {
                return nullTime; // Use the default time for null or '00:00'
            }
            if(time.includes('T')) {
                return time;
            }
            return dayjs().format('YYYY-MM-DD') + 'T' + time + ':00.000Z';
        }

        if(!item) return;

        let putObject = {
            opdrachtId: item.opdrachtId,
            vertrek: nullTime,
            aankomstWerf: nullTime,
            vertrekWerf: nullTime,
            aankomst: nullTime
        };

        const localTimes = await getLocalTimeClocks(item.opdrachtId);

        if(localTimes) {
            putObject.opdrachtId = localTimes.opdrachtId;
            putObject.vertrek = formatTime(localTimes.vertrek);
            putObject.aankomstWerf = formatTime(localTimes.aankomstWerf);
            putObject.vertrekWerf = formatTime(localTimes.vertrekWerf);
            putObject.aankomst = formatTime(localTimes.aankomst);
        }

        // Override the time based on the item type and provided time
        if (item['type'] && item['time']) {
            putObject[item['type']] = formatTime(item['time']);
        } else if (item['type']) { // If time is not provided for the item, use default
            putObject[item['type']] = nullTime;
        }


        console.log('putObject', putObject);
        const response = await axios.put(putTimesEndpoint + '?t=' + new Date().getTime(), putObject);

        if (response.status !== 200)
            return false;

        const responseData = JSON.stringify(response.data);

        if (responseData.includes('NOK')) {
            return false;
        } else {
            let orderData = await getOrder(localTimes.opdrachtId);
            const orderDetails = await getOrderDetails(localTimes.opdrachtId);
            orderData['details'] = orderDetails.orderDetailItems;
            
            updateLocalOpdracht(localTimes.opdrachtId, orderData);
            return true;
        }

    } catch (error) {
        console.error('Error putting time clock:', error);
        throw error;
    }
}