import { db } from '../../db';
import dayjs from 'dayjs';
import axios from 'axios';
import { timestampToTime } from '@/functions/TimeUtils';

const api = 'https://radis.maxicleaning.be/api/app/rma';
/*
    timeClock: '++id, opdrachtId, type, time, synced',
*/

export const getLocalTimeClocks = async (orderId) => {
    /*const data = await db.timeClock.where('opdrachtId').equals(orderId).toArray();

    const timeClocks = {
        opdrachtId: orderId,
        vertrek: timestampToTime(data.find((item) => item['type'] === 'vertrek')?.time),
        aankomstWerf: timestampToTime(data.find((item) => item['type'] === 'aankomstWerf')?.time),
        vertrekWerf: timestampToTime(data.find((item) => item['type'] === 'vertrekWerf')?.time),
        aankomst: timestampToTime(data.find((item) => item['type'] === 'aankomst')?.time),
    }
    */
    /* //console.log('getLocalTimeClocks', orderId);
    const data = await db.putTimes.get({opdrachtId: orderId});
    //console.log('getLocalTimeClocks', data);

    const timeClocks = {
        opdrachtId: orderId,
        vertrek: timestampToTime(data['vertrek']),
        aankomstWerf: timestampToTime(data['aankomstWerf']),
        vertrekWerf: timestampToTime(data['vertrekWerf']),
        aankomst: timestampToTime(data['aankomst']),
    }

    console.log('getLocalTimeClocks', timeClocks);

    return timeClocks; */
}

export const createOrUpdateTimeClock = async (item) => {
    console.log('table:')
    console.table(item);
    /* const data = await db.timeClock.get({opdrachtId: item['opdrachtId'], type: item['type']});
    if(data) {
        console.log('createOrUpdateTimeClock', 'data', data);
        let time;
        if(item['time'] === null)
            time = null;
        else if(item['time'].includes('T'))
            time = item['time'];
        else
            time = dayjs().format('YYYY-MM-DD') + 'T' + item['time'] + ':00.000Z';

        //console.log(time);
        await db.timeClock.update(data['id'], {
            time: time,
            synced: item['synced']
        })
    } else {
        await db.timeClock.put(item);
    } */
    const data = await db.putTimes.get({opdrachtId: item['opdrachtId']});
    if(data) {
        await db.putTimes.update(data['id'], item);
    } else {
        await db.putTimes.put(item);
    }


}


export const bulkCreateOrUpdateTimeClock = async (item) => {
    //console.log('bulkStoreTimeClock', item);
    /* await createOrUpdateTimeClock({
        opdrachtId: item['opdrachtId'],
        type: 'vertrek',
        time: item['vertrek'],
        synced: true
    })
    await createOrUpdateTimeClock({
        opdrachtId: item['opdrachtId'],
        type: 'aankomstWerf',
        time: item['aankomstWerf'],
        synced: true
    })
    await createOrUpdateTimeClock({
        opdrachtId: item['opdrachtId'],
        type: 'vertrekWerf',
        time: item['vertrekWerf'],
        synced: true
    })
    await createOrUpdateTimeClock({
        opdrachtId: item['opdrachtId'],
        type: 'aankomst',
        time: item['aankomst'],
        synced: true
    }) */
    //console.log('bulkStoreTimeClock', item);
    const data = await db.putTimes.get({opdrachtId: item['opdrachtId']});
    const content = {
        opdrachtId: item['opdrachtId'],
        vertrek: item['vertrek'],
        aankomstWerf: item['aankomstWerf'],
        vertrekWerf: item['vertrekWerf'],
        aankomst: item['aankomst']
    }
    if(data) {
        await db.putTimes.update(data['id'], content);
        //console.log('bulkStoreTimeClock', 'update', content);
    }
    //await db.putTimes.put(item);

}

export const updateApiTimeClock = async (orderId, item) => {
    console.log('updateApiTimeClock', orderId, item['opdrachtId']);
    //const localData = await db.putTimes.get({ opdrachtId: orderId });

    const localData = await db.putTimes.toArray();
    const firstItem = localData.find((item) => item['opdrachtId'] == orderId);
    //console.log('localData', _arr, firstItem);

    console.log('localData', localData, firstItem);

    const formatTime = (time) => {
        console.log('beforeFormat', time);
        if (!time || time === '00:00') {
            console.log('afterFormat', '1900-01-01T00:00:00.000Z')
            return '1900-01-01T00:00:00.000Z'; // Use the default time for null or '00:00'
        }
        if(time.includes('T')) {
            return time;
        }
        return dayjs().format('YYYY-MM-DD') + 'T' + time + ':00.000Z';
    };

    let putObject = {
        opdrachtId: firstItem.opdrachtId,
        vertrek: firstItem.vertrek,
        aankomstWerf: firstItem.aankomstWerf,
        vertrekWerf: firstItem.vertrekWerf,
        aankomst: firstItem.aankomst
    };

    //console.log('putObject', putObject)

    // Override the time based on the item type and provided time
    if (item['type'] && item['time']) {
        putObject[item['type']] = formatTime(item['time']);
    } else if (item['type']) { // If time is not provided for the item, use default
        putObject[item['type']] = '1900-01-01T00:00:00.000Z';
    }

    //console.log('putObject', putObject)

    const response = await axios.put(api + '/order-time-registration?t=' + new Date().getTime() , putObject);
    if (response.status === 200) {
        const responseData = JSON.stringify(response.data);
        //alert(response.data[0][""]);
            //console.log('trying to update this:', { ...item, time: '1900-01-01T00:00:00.000Z', synced: false }, putObject)
            console.log('result:', responseData)
        console.log('putObject', putObject);
        // Check if response includes 'NOK'
        if (responseData.includes('NOK')) {
            // If 'NOK', use the default time
            
            return false;
        } else {
            // If not 'NOK', use the item's time, or default if it's not set
            // const timeToUse = item['time'] ? formatTime(item['time']) : '1900-01-01T00:00:00.000Z';
            //await createOrUpdateTimeClock(putObject, false);
            await createOrUpdateTimeClock(putObject);
            return true;
        }
    }
}

export const pullTimeClocksFromApi = async () => {
    const putTimes = await db.putTimes.toArray();

    for (let i = 0; i < putTimes.length; i++) {
        const response = await axios.get(api + '/order-by-id/' + putTimes[i]['opdrachtId'] + '?t=' + new Date().getTime());

        const { vertrek, aankomstWerf, vertrekWerf, aankomst } = response.data;
        const timeClocks = {
            opdrachtId: putTimes[i]['opdrachtId'],
            vertrek: vertrek,
            aankomstWerf: aankomstWerf,
            vertrekWerf: vertrekWerf,
            aankomst: aankomst
        }

        await bulkCreateOrUpdateTimeClock(timeClocks);
    }

}


    /*return response.data; */