import { db } from '../../db';
import axios from 'axios';
/*
How it should be posted to the API:
{
"referenceId": 201400,
"eoType": "IMAGE",
"eoSubType": "SIGNATURE",
"eoFile": "signature222.jpg",
"eoOmschrijving": "Handtekening",
"eoOrigin": "RMA",
"referenceType": "OPDRACHT",
"eoVolgorde": 0,
"createdBy": "<loggedinUser>",
"eoBase64": "<base64>"
}
*/
const api = 'https://radis.maxicleaning.be/api/app/rma';
//const api = 'http://localhost:3000/api/app/rma';

export const getLocalExternalObjects = async (orderId) => {
    console.log('getLocalExternalObjects', orderId);
    const data = await db.externalObjects.where('opdrachtId').equals(parseInt(orderId)).toArray();

    const sortedData = data.sort((a, b) => a.eoVolgorde - b.eoVolgorde);
    console.log('getLocalExternalObjects', sortedData);
    return sortedData;
}

export const getRemoteExternalObjects = async (orderId) => {
    const response = await axios.get(api + '/external-objects-by-order-iD/' + orderId + '?t=' + new Date().getTime());

    console.log('getRemoteExternalObjects', response.data);
    return response.data;
}

export const createOrUpdateExternalObject = async (item) => {
    console.log('createOrUpdateExternalObject', item);
    //const data = await db.externalObjects.get({ referenceid: item['referenceid'], eoid: item['eoid'] });
    //const data = await db.externalObjects.where('referenceid').equals(parseInt(item['referenceid'])).and((item) => item.data.eofile === item['eofile']).first();
    const referenceid = parseInt(item['referenceid']);

    const allRecords = await db.externalObjects.where('referenceid').equals(referenceid).toArray();
    console.log('allRecords', allRecords.length);

    const filteredData = allRecords.filter(dbItem => dbItem.data && dbItem.data.eofile === item['eofile']);
    console.log('filteredData', filteredData.length);

    const dataObject = {
        referenceid: item['referenceid'],
        eotype: item['eotype'],
        eosubtype: item['eosubtype'],
        eofile: item['eofile'],
        eoomschrijving: item['eoomschrijving'],
        eoorigin: item['eoorigin'],
        referencetype: item['referencetype'],
        eovolgorde: item['eovolgorde'],
        createdby: item['createdby'],
        eobase64: item['eobase64']
    }

    if (filteredData.length > 0) {
        try {
            await db.externalObjects.update(filteredData[0]['id'], {
                data: dataObject,
                eovolgorde: item['eovolgorde'],
                eoid: item['eoid'],
            });
        } catch (error) {
            console.log(error);
        }
    } else {
        try {
            await db.externalObjects.put({
                referenceid: item['referenceid'],
                eovolgorde: item['eovolgorde'],
                eoid: item['eoid'],
                data: dataObject,
            });
        } catch (error) {
            console.log(error);
        }
        
    }
}

export const newCreateApiExternalObject = async (item) => {
    try {
        const formData = new FormData();
        
        formData.append('referenceid', item.opdrachtId);
        formData.append('eotype', 'IMAGE');
        formData.append('eosubtype', 'FOTO');
        formData.append('eofile', 'filenaam.jpg');
        formData.append('eoomschrijving', item.description);
        formData.append('eoorigin', 'RMA');
        formData.append('referencetype', 'OPDRACHT');
        formData.append('eovolgorde', item.eovolgorde);
        formData.append('createdby', '<LoggedinUser>');


        if (item.photo instanceof File) {
            formData.append('photo', item.photo, item.photo.name);
        } else {
            console.error("item.photo is not a file.");
            return;
        }

        const response = await axios.post(api+'/v2/external-object/create?t=' + new Date().getTime(), formData);
        console.log('newCreateApiExternalObject', response.data);
    } catch (error) {
        console.error('Error in newCreateApiExternalObject', error);
    }
}

export const newUpdateApiExternalObject = async (item) => {
    try {
        console.log('newUpdateApiExternalObject', item)

        const updateData = {
            referenceid: String(item.opdrachtId),
            eoomschrijving: String(item.description),
            eoid: String(item.eoid),
            eovolgorde: String(item.eovolgorde),
        }


        console.log(updateData);
        const response = await axios.post(api+'/v2/external-object/update?t=' + new Date().getTime(), updateData);


        console.log('newUpdateApiExternalObject', response.data);

    } catch (error) {
        console.error('Error in newUpdateApiExternalObject', error);
    }
}

export const newDeleteApiExternalObject = async (item) => {
    try {
        console.log('newDeleteApiExternalObject', item)

        const deleteData = {
            eoid: String(item.eoid)
        }

        console.log(deleteData);
        const response = await axios.post(api+'/v2/external-object/delete/' + item.opdrachtId + '/RMA?t=' + new Date().getTime() , deleteData);

        console.log('newDeleteApiExternalObject', response.data);

    } catch (error) {
        console.error('Error in newDeleteApiExternalObject', error);
    }
}

export const updateApiExternalObject = async (item) => {
    console.log('updateApiExternalObject', item);

    try {
        // Fetch items with the same referenceid
        const data = await db.externalObjects
            .where('referenceid')
            .equals(parseInt(item['referenceid']))
            .toArray();

        let externalObjectItems = data.map(d => d['data']); // Extract existing data items

        // Check if item with the same eoid exists
        const existingIndex = data.findIndex(d => d['eoid'] === item['eoid']);

        if (existingIndex !== -1) {
            // Update the existing item
            console.log('Updated item', item);
            externalObjectItems[existingIndex] = item; // Update in array
            await db.externalObjects.update(data[existingIndex].id, item); // Update in DB
        } else {
            // Add new item
            console.log('Added new item', item);
            externalObjectItems.push(item); // Add to array
            await db.externalObjects.add(item); // Add to DB
        }


        console.log('postExternalObjects', externalObjectItems.length);
        // Prepare the postObject
        const postObject = {
            referenceid: item['referenceid'],
            externalobjectitems: externalObjectItems
        };

        await clearLocalExternalObjects(item['referenceid']);

        console.log('postObject', postObject);



        // Send the post request
        const response = await axios.post(api + '/external-object', postObject);
        
        console.log('updateApiExternalObject', response.data);

    } catch (error) {
        console.error('Error in updateApiExternalObject', error);
    }
};

const clearLocalExternalObjects = async (orderId) => {
    await db.externalObjects.where('referenceid').equals(orderId).delete();
}

export const getApiExternalObjects = async (orderId) => {
    const response = await axios.get(api + '/external-objects-by-order-iD/' + orderId + '?t=' + new Date().getTime());
    console.log('getApiExternalObjects', response)

    clearLocalExternalObjects(orderId);

    //await db.externalObjects.where('referenceid').equals(parseInt(orderId)).delete();
    const items = response.data.externalobjectitems;

    for(let i = 0; i < items.length; i++) {
        console.log('getApiExternalObjects', items[i])
        await createOrUpdateExternalObject(items[i]);
    }

    console.log('getApiExternalObjects', response.data)

    return response.data;
}

export const newCreateApiSignature = async (item) => {
    console.log('newCreateApiSignature', item);

    try{
        const formData = new FormData();
        formData.append('referenceid', item.opdrachtId);
        formData.append('eotype', 'IMAGE');
        formData.append('eosubtype', 'SIGNATURE');
        formData.append('eofile', 'signature.jpg');
        formData.append('eoomschrijving', 'Handtekening');
        formData.append('eoorigin', 'RMA');
        formData.append('referencetype', 'OPDRACHT');
        formData.append('eovolgorde', '900');
        formData.append('createdby', '<LoggedinUser>');
        
        if (item.photo instanceof File) {
            formData.append('photo', item.photo, item.photo.name);
        } else {
            console.error("item.photo is not a file.");
            return;
        }
        const response = await axios.post(api+'/v2/external-object/create', formData);
        console.log('newCreateApiExternalObject', response.data);
    } catch (error) {
        console.error('Error in newCreateApiExternalObject', error);
    }
}

export const getExternalObjectsCount = async (orderId) => {
    try {
        const response = await axios.get(api + '/v2/external-object/count', { params: { opdrachtId: orderId } });
        console.log('getExternalObjectsCount', api + '/v2/external-object/count', { query: { opdrachtId: orderId } });
        console.log('getExternalObjectsCount', response.data, orderId);
        return response.data;
    } catch (error) {
        console.error('Error in getExternalObjectsCount', error);
        return '?';
    }
}

export const getWerkbon = async (orderId) => {
    try {
        const response = await axios.get(api + '/v2/external-object/' + orderId + '/opdrachtbon');
        console.log('getWerkbon', response.data, orderId);
        return response.data;
    } catch (error) {
        console.error('Error in getWerkbon', error);
        return null;
    }
}

export const getTempWerkbon = async (orderId) => {
    try {
        const response = await axios.get(api + '/v2/external-object/' + orderId + '/opdrachtbon/temp');
        console.log('getTempWerkbon', response.data, orderId);
        return response.data;
    } catch (error) {
        console.error('Error in getWerkbon', error);
        return null;
    }
}

export const getSignature = async (orderId) => {
    try {
        const response = await axios.get(api + '/v2/external-object/' + orderId + '/signature');
        console.log('getSignature', response.data, orderId);
        return response.data;
    } catch (error) {
        console.error('Error in getSignature', error);
        return null;
    }
}