<script setup>
    import { ref } from 'vue'
    const loaded = ref(false);

    defineExpose({ loaded });
</script>

<template>
    <div v-if="!loaded" class="absolute top-0 left-0 z-[100000000] bg-mc-primary w-full h-full flex items-center justify-center">
        <span class="text-white text-4xl">Loading...</span>
    </div>
</template>