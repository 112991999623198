<script setup>
import WerklijstItem from '@/components/WerklijstItem.vue'
import AppLayout from '@/layouts/AppLayout.vue'
import { useApiStore } from '@/stores/apiStore';
import { computed } from 'vue';
import router from '@/router';
import { useDateStore } from '@/stores/dateStore';
import ReturnButton from '@/components/ReturnButton.vue';
import { pullTimeClocksFromApi } from '@/services/time-service';
import { useTranslationStore } from '@/stores/translationStore';

const dateStore = useDateStore()
const translationStore = useTranslationStore()

defineProps(['item'])

const { date, typeWagen, bestuurderId, begeleiderId } = router.currentRoute.value.query

const apiStore = useApiStore()
pullTimeClocksFromApi();
apiStore.v2loadOpdrachten(date, typeWagen, bestuurderId, begeleiderId);

const _worklistItems = computed(() => {
    const worklistItems = apiStore.worklists ?? []
    console.log('worklistItems', begeleiderId)
    let filteredWorklistItems;
    filteredWorklistItems = worklistItems.filter(item => item.date == date && item.typeWagen == typeWagen && item.bestuurderId == bestuurderId && item.begeleiderId == begeleiderId);

    /* if(begeleiderId)
        filteredWorklistItems = worklistItems.filter(item => item.date == date && item.typeWagen == typeWagen && item.bestuurderId == bestuurderId && item.begeleiderId == begeleiderId);
    else 
        filteredWorklistItems = worklistItems.filter(item => item.date == date && item.typeWagen == typeWagen && item.bestuurderId == bestuurderId && item.begeleiderId == null);
 */
        
    //Sort first by status, then by swtEersteWerk, then by volgr make sure the "Uitgevoerde" status are at the bottom
    const sortedWorklistItems = filteredWorklistItems.sort((a, b) => {
        if (a.statusUitvoeringOpdracht == 'Uitgevoerd' && b.statusUitvoeringOpdracht != 'Uitgevoerd') return 1;
        if (a.statusUitvoeringOpdracht != 'Uitgevoerd' && b.statusUitvoeringOpdracht == 'Uitgevoerd') return -1;
        if (a.swtEersteWerk && !b.swtEersteWerk) return -1;
        if (!a.swtEersteWerk && b.swtEersteWerk) return 1;
        return a.volgr - b.volgr;
    });
    return sortedWorklistItems;
})

</script>

<template>
  <AppLayout>
    <template #left>
        <div class="bg-mc-secondary flex items-center rounded px-6 py-2">MC</div>
        <div class="bg-mc-secondary flex items-center rounded px-6 py-2">{{ dateStore.formattedDate }}</div>
        <div class="bg-mc-secondary flex items-center rounded px-6 py-2">{{ typeWagen }}</div>
    </template>
    <template #title>{{ translationStore.t('werklijst.titel') }}</template>
    <div v-if="false">
        <div v-for="worklist in apiStore.worklists" :key="worklist">
            {{ worklist.date }}
        </div>
    </div>

    <div class="grid grid-cols-12 items-center px-4 pb-2 font-medium leading-tight text-sm">
      <div>
        <span>{{ translationStore.t('werklijst.volgnr') }}</span>
      </div>
      <div class="col-span-4 flex flex-col">
        <span>{{ translationStore.t('werklijst.naam_klant') }}</span>
        <span>{{ translationStore.t('werklijst.contact') }}</span>
        <span>{{ translationStore.t('werklijst.adres') }}</span>
      </div>
      <div class="col-span-2 flex flex-col">
        <span>{{ translationStore.t('werklijst.telefoon_klant') }}</span>
        <span>{{ translationStore.t('werklijst.telefoon_contact') }}</span>
      </div>
      <div class="col-span-2 flex flex-col">
        <span>{{ translationStore.t('werklijst.werkbon') }}</span>
        <span>{{ translationStore.t('werklijst.voorkeurstijdstip') }}</span>
        <span>{{ translationStore.t('werklijst.status') }}</span>
        <span>{{ translationStore.t('werklijst.bestanden') }}</span>
      </div>
      <div class="col-span-3 flex flex-col">
        <span>{{ translationStore.t('werklijst.tijdstippen') }}</span>
      </div>
    </div>
    <div v-if="false">
        <WerklijstItem v-for="item in _worklistItems" :key="item.id" :item="item"/>
    </div>
    <table>
        <tr v-for="item in apiStore.v2orders" :key="item.id">
            <WerklijstItem :item="item"/>
        </tr>
    </table>
  </AppLayout>
  <ReturnButton>{{ translationStore.t('werklijst.terug_knop') }}</ReturnButton>
</template>
