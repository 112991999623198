import { newDb, staticdb } from "../../db";
import { timestampToTime } from '@/functions/TimeUtils';

//import { getLocalTimeClocks } from './time-service';

export const storeLocalAgendaItems = (items) => {
    console.log('Clearing agendaItems');
    newDb.agendaItems.clear();
    items.forEach((item) => {
        newDb.agendaItems.add(item);
    });
}

export const storeLocalOpdrachten = async (opdrachten) => {
    console.log('Clearing opdrachten');
    //TODO: Disabled for refresh logic
    //newDb.orders.clear();
    for (const opdracht of opdrachten) {
        const exists = await newDb.orders.where('opdrachtId').equals(parseInt(opdracht.opdrachtId)).first();
        console.log('Exists:', exists);
        if (exists) {
            await newDb.orders.update(parseInt(exists.id), opdracht);
        } else {
            await newDb.orders.add(opdracht);
        }
    }
}

export const storeLocalGenCodes = (genCodes) => {
    console.log('Clearing genCodes', genCodes);
    staticdb.genCodes.clear();
    genCodes.forEach((genCode) => {
        staticdb.genCodes.add(genCode);
    });
}

export const upsertWerkbonForm = async (opdrachtId, werkbonForm) => {
    const data = await newDb.werkbonForm
    .where('opdrachtId').equals(parseInt(opdrachtId))
    .first();

    if(data) {
        await newDb.werkbonForm.update(data.id, {
            ...werkbonForm
        });
    } else {
        await newDb.werkbonForm.add({
            opdrachtId: opdrachtId,
            ...werkbonForm
        });
    }
}

export const updateLocalOpdracht = async (opdrachtId, opdracht) => {
    try {
        const data = await newDb.orders
        .where('opdrachtId').equals(parseInt(opdrachtId))
        .first();

        await newDb.orders.update(data.id, {
            ...opdracht
        });
    } catch (error) {
        console.error('Error updating local opdracht:', error);
        throw error;
    }

}

export const getLocalAgendaItems = async (werknemerId) => {
    const items = await newDb.agendaItems
    .where('bestuurderId')
    .equals(parseInt(werknemerId))
    .or('begeleiderId')
    .equals(parseInt(werknemerId))
    .toArray();

    return items;
}

export const getLocalOpdrachten = async (date, typeWagen, bestuurderId, begeleiderId) => {
    try {
        console.log('Fetching local opdrachten:', date, typeWagen, bestuurderId, begeleiderId);
        const items = await newDb.orders
        .where('datumUitvoering').equals(date)
        .filter(order => {
            let match = true;
            if (typeWagen) {
                match = match && order.typeWagen === typeWagen;
            }
            if (bestuurderId) {
                match = match && order.bestuurderId === parseInt(bestuurderId);
            }
            if (begeleiderId) {
                match = match && order.begeleiderId === parseInt(begeleiderId);
            } else {
                match = match && order.begeleiderId === null;
            }
            return match;
        })
        .toArray();

        console.log('Local opdrachten:', items);

        // Custom sorting function
        items.sort((a, b) => {
            // First, compare statusUitvoeringOpdracht
            if (a.statusUitvoeringOpdracht === null && b.statusUitvoeringOpdracht !== null) {
                return -1; // a comes before b
            } else if (a.statusUitvoeringOpdracht !== null && b.statusUitvoeringOpdracht === null) {
                return 1; // b comes before a
            }
            // If statusUitvoeringOpdracht is equal, compare swtEersteWerk
            if (a.swtEersteWerk !== b.swtEersteWerk) {
                return b.swtEersteWerk - a.swtEersteWerk; // Descending order
            }
            // If swtEersteWerk is equal, compare volgr
            if (a.volgr !== b.volgr) {
                return a.volgr - b.volgr; // Ascending order
            }
            // If all previous criteria are equal, return 0
            return 0; // No change in order
        });
        

        return items;
    } catch (error) {
        console.error('Error fetching local opdrachten:', error);
        throw error;
    }
}

export const getLocalOpdracht = async (opdrachtId) => {
    try {
        console.log('Fetching local opdracht:', opdrachtId);
        const parsedOpdrachtId = parseInt(opdrachtId, 10);
        if (isNaN(parsedOpdrachtId)) {
            throw new Error(`Invalid opdrachtId: ${opdrachtId}`);
        }

        const opdracht = await newDb.orders
        .where('opdrachtId').equals(parsedOpdrachtId)
        .first();

        if (!opdracht) {
            throw new Error('Opdracht not found');
        }

        console.log('Local opdracht:', opdrachtId);
        console.log(opdracht);
        return opdracht;
    } catch (error) {
        console.error('Error fetching local opdracht:', error);
        throw error;
    }
}

export const getLocalWerkbonForm = async (opdrachtId) => {
    try {
        const werkbonForm = await newDb.werkbonForm
        .where('opdrachtId').equals(parseInt(opdrachtId))
        .first();

        console.log('Local werkbonForm:', opdrachtId);
        console.log(werkbonForm);
        return werkbonForm ;
    } catch (error) {
        console.error('Error fetching local werkbonForm:', error);
        throw error;
    }
}

export const getLocalGenCodes = async () => {
    try {
        const genCodes = await staticdb.genCodes.toArray();
        return genCodes;
    } catch (error) {
        console.error('Error fetching local genCodes:', error);
        throw error;
    }
}

export const getLocalTimeClocks = async (opdrachtId) => {
    try {
        console.log('Fetching local timeClocks:', opdrachtId);
        const order = await newDb.orders
        .where('opdrachtId').equals(parseInt(opdrachtId))
        .first();

        return {
            opdrachtId: order.opdrachtId,
            vertrek: timestampToTime(order.vertrek),
            aankomstWerf: timestampToTime(order.aankomstWerf),
            vertrekWerf: timestampToTime(order.vertrekWerf),
            aankomst: timestampToTime(order.aankomst),
        };
    } catch (error) {
        console.error('Error fetching local timeStamps:', error);
        throw error;
    }
}