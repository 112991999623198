<script setup>
    import { useExternalObjectsModalStore } from '@/stores/externalobjectModalStore';
import { useTranslationStore } from '@/stores/translationStore';
    import { computed } from 'vue';

    const externalObjectsModalStore = useExternalObjectsModalStore();
    const translationStore = useTranslationStore();

    const props = defineProps(['externalObject']);

    const extension = props.externalObject.eofile.split('.').pop().toLowerCase();

    const image = computed(() => {
        //base64 decode image and return it as url that cna be used in image src
        //get file extension from eoFile property
        console.log(props.externalObject)
        

        if(extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'bmp')
            return 'data:image/'+extension+';base64,' + props.externalObject.eobase64;
        if(extension === 'docx')
            return '/docx_placeholder.png';
        if(extension === 'pdf')
            return '/pdf_placeholder.png';
        
        return 'https://via.placeholder.com/150';
    });

    const openModal = () => {
        console.log('openingmodal', {...props.externalObject, image: image.value, extension})
        externalObjectsModalStore.openUpdate({...props.externalObject, image: image.value, extension});
    }
</script>

<template>
    <button @click="openModal()" class="bg-mc-primary rounded-lg text-white text-left flex flex-col relative">
        <div v-if="false">{{ props.externalObject.eoorigin }} {{ props.externalObject.eofile }}</div>
        <img :src="image" alt="" class="rounded-t-lg h-48 w-full object-cover">
        <div class="py-6 px-4" :class="[
            props.externalObject.eoomschrijving == '' ? 'text-sm text-gray-600' : 'text-base'
        ]">
            {{ props.externalObject.eoomschrijving == '' ? translationStore.t('eo.geen_omschrijving') : props.externalObject.eoomschrijving }}
        </div>
    </button>
</template>