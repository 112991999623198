<script setup>
import { onMounted, ref } from 'vue'
import {
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption
} from '@headlessui/vue'
import router from '@/router'
import { useAgendaStore } from '@/stores/agendaStore';
import { useTranslationStore } from '@/stores/translationStore';

const agendaStore = useAgendaStore()
const translationStore = useTranslationStore()

const props = defineProps(['items']);

const selected = ref(props.items[0])
const cachedSelected = ref(props.items[0])

const clicked = (item) => {
  if (item?.date == cachedSelected.value?.date && item.typeWagen == cachedSelected.value?.typeWagen && item.bestuurderId == cachedSelected.value?.bestuurderId && item.begeleiderId == cachedSelected.value?.begeleiderId) {
    //'?date=' + Dt + '&typeWagen=' + TypeWagen + '&bestuurderId=' + BestuurderID + '&begeleiderId=' + BegeleiderID
    //item.datumUitvoering, item.typeWagen, item.bestuurderId, item.begeleiderId
    localStorage.setItem('worklistFilters', JSON.stringify({ date: item.datumUitvoering, typeWagen: item.typeWagen, bestuurderId: item.bestuurderId, begeleiderId: item.begeleiderId}))
    router.push({ name: 'werklijst', query: { date: item.datumUitvoering, typeWagen: item.typeWagen, bestuurderId: item.bestuurderId, begeleiderId: item.begeleiderId} })
  }
  agendaStore.selected = null;
  //alert('going to next page')
  agendaStore.select(item);
  cachedSelected.value = selected.value
}

onMounted(() => {
  agendaStore.select(props.items[0])
  cachedSelected.value = selected.value
})
</script>

<template>
  <RadioGroup v-model="selected">
    <div class="space-y-2">
      <RadioGroupOption
        as="template"
        v-for="(item, index) in items"
        :key="index"
        :value="item"
        v-slot="{ active, checked }"
        @click="clicked(item)"
      >
        
        <div
          :class="[
            active ? 'border-red-600 ring-2 ring-red-600' : 'border-gray-300',
            'relative block cursor-pointer rounded-lg border px-6 py-6 shadow-sm focus:outline-none sm:flex sm:justify-between',
            index % 2 == 0 ? 'bg-mc-primary' : 'bg-mc-secondary'
          ]"
        >
          <span class="grid grid-cols-12 items-center justify-between w-full text-white text-left">
            <div>
              <input type="checkbox" :checked="active" disabled />
            </div>
            <RadioGroupLabel as="div" class="col-span-3">
              <h3>{{ translationStore.t('agenda.voertuig') }}</h3>
              <span class="font-medium">{{ item.typeWagen }}</span>
            </RadioGroupLabel>
            <RadioGroupDescription as="div" class="col-span-3">
              <h3>{{ translationStore.t('agenda.bestuurder') }}</h3>
              <span class="font-medium">{{ item.bestuurder }}</span>
            </RadioGroupDescription>
            <RadioGroupDescription as="div" class="col-span-3">
              <h3>{{ translationStore.t('agenda.begeleider') }}</h3>
              <span class="font-medium">{{ item.begeleider }}</span>
            </RadioGroupDescription>
            <RadioGroupDescription as="div" class="col-span-2 text-center">
              <h3>{{ translationStore.t('agenda.aantal_opdrachten') }}</h3>
              <span class="font-medium">{{ item.aant }}</span>
            </RadioGroupDescription>
          </span>
          <span
            :class="[
              active ? 'border' : 'border-2',
              checked ? 'border-red-600' : 'border-transparent',
              'pointer-events-none absolute -inset-px rounded-lg'
            ]"
            aria-hidden="true"
          />
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>
