import { newCreateApiSignature } from "@/services/external-object-service";

export const prepareBase64ExternalObject = async (base64, opdrachtId) => {
    const blob = base64ToBlob(base64);
    const file = convertBlobToFile(blob);

    const data = {
        opdrachtId: opdrachtId,
        photo: file,
    }
    console.log('formData', data)
    await newCreateApiSignature(data);


    return;
}

export const convertBlobToFile = (blob) => {
    const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
    const fileName = `signature_${timestamp}.jpg`;

    const file = new File([blob], fileName, { type: 'image/jpeg' });

    return file;
}

export const base64ToBlob = (base64) => {
    try {
        const base64Data = base64.split(',')[1];

        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        return new Blob([byteArray], { type: 'image/jpeg' });
    } catch (error) {
        console.error('Error converting base64 to blob:', error);
        throw error; // Rethrow the error to catch it where base64ToBlob is called
    }
}
