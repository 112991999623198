<script setup>
import { ref, watchEffect, onMounted, onUnmounted } from 'vue'
import { useDevicesList, useUserMedia } from '@vueuse/core'
import { createOrUpdateExternalObject, updateApiExternalObject } from '@/services/external-object-service';
import { useLoadingStore } from '@/stores/loadingStore';
import router from '@/router';

const loadingStore = useLoadingStore();
const props = defineProps(['id']);

const canvas = ref(null);
let animationFrameId = null;

const _cameras = ref([]);

const currentCamera = ref()
const { videoInputs: cameras } = useDevicesList({
    requestPermissions: true,
    onUpdated() {
        _cameras.value = cameras.value;
        console.log('cameras', cameras.value);
        if (cameras.value.length == 2) {
            console.log('camera', cameras.value[1]);
            // Select the second camera if there are at least 2 cameras

            currentCamera.value = cameras.value[1].deviceId;
        } else if (cameras.value.length === 1) {
            // Select the first camera if there is only one
            console.log('camera', cameras.value);
            currentCamera.value = cameras.value[0].deviceId;
        }
    },
})

const video = ref()

const { stream, enabled } = useUserMedia({
    constraints: { 
        video: { 
            deviceId: currentCamera,
                width: { ideal: 1280 },   // HD resolution
                height: { ideal: 720 },   // HD resolution
            frameRate: { ideal: 30 }, // Moderate frame rate
            focusMode: { exact: 'auto' }, // Auto focus mode
        } 
    },
})

watchEffect(() => {
  if (stream.value) {
    video.value.srcObject = stream.value;
    startStreamingToCanvas();
  }
})

const startStreamingToCanvas = () => {
  const drawVideoFrame = () => {
    const canvasElement = canvas.value;
    const videoElement = video.value;
    if (canvasElement && videoElement) {
      const context = canvasElement.getContext('2d');
      canvasElement.width = videoElement.videoWidth;
      canvasElement.height = videoElement.videoHeight;
      context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
      animationFrameId = requestAnimationFrame(drawVideoFrame);
    }
  };
  drawVideoFrame();
};

const stopStreamingToCanvas = () => {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }
};

loadingStore.loading = true;

onMounted(() => {
    //setBackCamera()
    enabled.value = true;
    setTimeout(() => {
        switchCamera()
    }, 1000);
  startStreamingToCanvas();
});

onUnmounted(() => {
  stopStreamingToCanvas();
});


const takeScreenshot = async () => {
    const videoElement = video.value;
    const canvasElement = canvas.value;
    if (videoElement && canvasElement) {
        const context = canvasElement.getContext('2d');
        canvasElement.width = videoElement.videoWidth;
        canvasElement.height = videoElement.videoHeight;
        context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
        const imageData = canvasElement.toDataURL('image/png');
        // Now imageData contains a base64-encoded image of the current video frame
        // You can display it, save it, or send it to a server
        console.log(imageData);

        const pureBase64Encoded = imageData.replace(/^data:image\/(png|jpg);base64,/, '');

        const object = {
            referenceid: props.id,
            eotype: 'IMAGE',
            eofile: props.id + '_' + Date.now() + 'foto.png',
            eovolgorde: 50,
            eoomschrijving: null,
            eoorigin: 'RMA',
            referencetype: 'OPDRACHT',
            createdby: '<LoggedInUser>',
            eosubtype: 'FOTO',
            eobase64: pureBase64Encoded,
            fileloadtimems: 2
        }
        console.log('object', object);
        await createOrUpdateExternalObject(object);
        await updateApiExternalObject(object);
        router.push({ name: 'external-objects', params: { id: props.id } });
    }
};

  const switchCamera = () => {
    console.log('SwitchingCamera', false)
    enabled.value = false;
    setTimeout(() => {
        currentCamera.value = cameras.value[1].deviceId;
        enabled.value = true;
        loadingStore.loading = false;
    }, 500);
    
  }

const back = () => {
    router.push({ name: 'external-objects', params: { id: props.id } });
}
</script>

<template>
    <div class="bg-black text-white relative h-screen overflow-none">
        <div class="absolute top-0 left-0" v-if="false">
            V1 - {{ _cameras.length }} - {{  cameras.length  }}
        </div>
        <button class="absolute top-0 right-0 m-4 px-8 py-4 bg-mc-primary rounded-lg z-50" v-if="false" @click="switchCamera()">
            Switch Camera {{ enabled }}
        </button>
        <button class="absolute top-[40%] right-0 m-4 px-8 py-4 bg-mc-primary z-50 rounded-full" @click="takeScreenshot()">
            Foto Maken
        </button>
        <button class="absolute top-[1%] right-0 m-4 px-8 py-4 bg-mc-primary rounded-lg z-50" @click="back()">
            Terug
        </button>

        <div>
            <video ref="video" muted autoplay class="h-screen w-full" />
        </div>
        <div>
            <canvas ref="canvas" style="display: none;"></canvas>
        </div>
    </div>
</template>