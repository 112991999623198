import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useApiStore } from './stores/apiStore'
//import * as Sentry from "@sentry/vue";


import App from './App.vue'
import router from './router'
import VueSignaturePad from 'vue-signature-pad';

/* Sentry.init({
    dsn: "https://7c5c07303d238c136036a738f6affe94@o4504131042672640.ingest.sentry.io/4506468666048512",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
   */


const app = createApp(App)

const pinia = createPinia()
app.use(pinia)
app.use(router)
app.use(VueSignaturePad);

app.mount('#app')

// Access the Pinia store after the app is mounted
const apiStore = useApiStore()



if(navigator.onLine) {
    apiStore.connected();
} else {
    apiStore.disconnected();
}
    
//console.log('online', navigator.onLine);


window.addEventListener('offline', function () {
    apiStore.disconnected();
})

window.addEventListener('online', function () {
    apiStore.connected();
})
