<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { EnvelopeIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useWerkbonModalStore } from '@/stores/werkbonModalStore';
import { useApiStore } from '@/stores/apiStore';
import TimeComponent from './TimeComponent.vue';
import HandtekeningModal from './HandtekeningModal.vue';
import { computed, onMounted, watch } from 'vue';
import { asyncComputed } from '@vueuse/core';
import { getRmaEmail } from '@/functions/localCache'; 
import { useTranslationStore } from '@/stores/translationStore';

const werkbonModalStore = useWerkbonModalStore();
const apiStore = useApiStore();
const translationStore = useTranslationStore();

const uitgevoerd = computed(() => apiStore.v2selectedOpdracht.statusUitvoeringOpdracht === 'Uitgevoerd');
const signature = computed(() => {
    if (werkbonModalStore.form.handtekening) {
        return werkbonModalStore.form.handtekening;
    }

    if (!werkbonModalStore?.signature?.eobase64) {
        return null;
    }
    return `data:image/png;base64,${werkbonModalStore.signature.eobase64}`;
});
const handtekeningStatus = computed(() => apiStore.v2genCodes.filter(item => item.CodeGroup === 'RMA_HandtekeningStatus'));
const statusUitvoeringOpdracht = computed(() => apiStore.v2genCodes.filter(item => item.CodeGroup === 'RMA_StatusUitvoeringOpdracht'));
const redenNietUitgevoerd = computed(() => apiStore.v2genCodes.filter(item => item.CodeGroup === 'RMA_RedenNietUitvoering'));

const addEmailToField = async () => {
    const email = await getRmaEmail();
    const { form } = werkbonModalStore;
    if (!form.email) {
        form.email = email;
    } else if (!form.email.includes(email)) {
        form.email += form.email.slice(-1) === ';' ? email : `; ${email}`;
    }
};
</script>

<template>
    <HandtekeningModal />
    <TransitionRoot as="template" :show="werkbonModalStore.modalOpen">
      <Dialog as="div" class="relative z-[10000]">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
  
        <div class="fixed inset-0 z-[10000] w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full mx-12" :class="[
                uitgevoerd ? 'bg-gray-400' : 'bg-mc-primary'
              
              ]">
                <button @click="werkbonModalStore.modalOpen = false" class="right-5 top-5 absolute outline-none">
                    <XMarkIcon class="h-10 w-10 text-white" aria-hidden="true" />
                </button>
                <div class="flex flex-col text-white w-full gap-2 py-4">
                    <div class="px-6">
                        <div v-if="werkbonModalStore.errors.general" class="bg-red-100 border border-red-600 rounded-lg text-red-800 p-4 my-4">
                            Error: {{ werkbonModalStore.errors.general }}
                        </div>
<!--                         {{ werkbonModalStore.form }}
 -->                        <div class="flex flex-col gap-2">
                            <h3>{{ translationStore.t('werkbon.modal.btw_regeling') }}</h3>
                            <p>{{ translationStore.t('werkbon.modal.ik_ondergetekende') }}</p>
                            <div class="flex justify-between gap-4">
                                <button class="maxi-button w-full" 
                                    :disabled="uitgevoerd"
                                    :class="[werkbonModalStore.form.attestStatus === 'ja' ? uitgevoerd ? 'bg-gray-700' : 'bg-mc-tertiary' : uitgevoerd ? 'bg-gray-500' : '']"
                                    @click="werkbonModalStore.form.attestStatus = 'ja'"
                                >
                                    {{ translationStore.t('werkbon.modal.ja') }}
                                </button>
                                <button class="maxi-button w-full" 
                                    :disabled="uitgevoerd"
                                    :class="[werkbonModalStore.form.attestStatus === 'nee' ? uitgevoerd ? 'bg-gray-700' : 'bg-mc-tertiary' : uitgevoerd ? 'bg-gray-500' : '']"
                                    @click="werkbonModalStore.form.attestStatus = 'nee'"
                                >
                                    {{ translationStore.t('werkbon.modal.nee') }}
                                </button>
                                <button class="maxi-button w-full" 
                                    :disabled="uitgevoerd"
                                    :class="[werkbonModalStore.form.attestStatus === 'nvt' ? uitgevoerd ? 'bg-gray-700' : 'bg-mc-tertiary' : uitgevoerd ? 'bg-gray-500' : '']"
                                    @click="werkbonModalStore.form.attestStatus = 'nvt'"
                                >
                                {{ translationStore.t('werkbon.modal.niet_van_toepassing') }}
                                </button>
                                <button class="maxi-button w-full"
                                    :disabled="uitgevoerd"
                                    :class="[!werkbonModalStore.form.attestStatus ? uitgevoerd ? 'bg-gray-700' : 'bg-mc-tertiary' : uitgevoerd ? 'bg-gray-500' : '']"
                                    @click="werkbonModalStore.form.attestStatus = ''"
                                >
                                    {{ translationStore.t('werkbon.modal.niet_geselecteerd') }}
                                </button>
                            </div>
                            
                        </div>
                    </div>
                    <div class="px-6 flex w-full gap-4">
                        <div class="w-full">
                            <label for="">{{ translationStore.t('werkbon.modal.opmerking') }}</label>
                            <textarea :disabled="uitgevoerd" v-model="werkbonModalStore.form.opmerking" cols="30" rows="3" class="w-full mt-1 maxi-input" :placeholder="translationStore.t('werkbon.modal.opmerking_placeholder')" :class="[werkbonModalStore.errors.opmerking ? 'maxi-error' : '']"></textarea>
                            <span class="maxi-suberror" v-if="werkbonModalStore.errors.opmerking">{{ werkbonModalStore.errors.opmerking }}</span>
                        </div>
                        <div class="w-full">
                            <label for="">{{ translationStore.t('werkbon.modal.opmerking_klant') }}</label>
                            <textarea :disabled="uitgevoerd" v-model="werkbonModalStore.form.opmerkingKlant" cols="30" rows="3" class="w-full mt-1 maxi-input" :placeholder="translationStore.t('werkbon.modal.opmerking_klant_placeholder')" :class="[werkbonModalStore.errors.opmerkingKlant ? 'maxi-error' : '']"></textarea>
                            <span class="maxi-suberror" v-if="werkbonModalStore.errors.opmerkingKlant">{{ werkbonModalStore.errors.opmerkingKlant }}</span>
                        </div>
                    </div>
                    <div class="px-6 grid grid-cols-6 gap-4">
                        <div class="col-span-4 grid grid-cols-2 gap-2 content-start">
                            <div class="relative">
                                <label for="">{{ translationStore.t('werkbon.modal.naam') }}</label>
                                <input 
                                :disabled="uitgevoerd"
                                type="text" 
                                v-model="werkbonModalStore.form.naam" 
                                class="maxi-input" :class="[werkbonModalStore.errors.naam ? 'maxi-error' : '']" 
                                :placeholder="translationStore.t('werkbon.modal.naam_placeholder')"
                                @input="werkbonModalStore.errors.naam = ''"
                                >
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.naam">{{ werkbonModalStore.errors.naam }}</span>
                            </div>
                            <div class="relative">
                                <label for="">{{ translationStore.t('werkbon.modal.status') }}</label>
                                <div class="relative">
                                    <select :disabled="uitgevoerd" class="maxi-input" v-model="werkbonModalStore.form.status" :class="[werkbonModalStore.errors.status ? 'maxi-error' : '']">
                                        <option disabled value="">{{ translationStore.t('werkbon.modal.selecteer_status') }}</option>
                                        <option value=""></option>
                                        <option :value="item.Value" v-for="item in handtekeningStatus" :key="item">
                                            {{ translationStore.lang === 'nl' ? item.NL : item.FR }}
                                        </option>
                                    </select>
                                    <span v-if="false && !werkbonModalStore.form.status" class="absolute top-1.5 left-2 text-black z-50 opacity-30 pointer-events-none">Selecteer de status</span>
                                </div>
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.status">{{ werkbonModalStore.errors.status }}</span>
                            </div>
                            <div class="col-span-2 relative">
                                <label for="">{{ translationStore.t('werkbon.modal.email') }}</label>
                                <div class="flex gap-2">
                                    <input type="email" 
                                    :disabled="uitgevoerd"
                                    v-model="werkbonModalStore.form.email" 
                                    class="maxi-input" 
                                    :class="[werkbonModalStore.errors.email ? 'maxi-error' : '']" 
                                    :placeholder="translationStore.t('werkbon.modal.email_placeholder')"
                                    @input="werkbonModalStore.errors.email = ''"
                                    >
                                    <button class="maxi-button flex gap-2 items-center"
                                        :class="[uitgevoerd ? 'bg-gray-700' : 'bg-mc-tertiary']"
                                        :disabled="uitgevoerd"
                                    @click="addEmailToField"
                                    >
                                        <EnvelopeIcon class="h-5 w-5 text-white" aria-hidden="true" />
                                        MC
                                    </button>
                                </div>
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.email">{{ werkbonModalStore.errors.email }}</span>
                            </div>
                            <div class="col-span-2 relative">
                                <label for="">{{ translationStore.t('werkbon.modal.handtekening') }}</label>
                                <div class="h-44 border border-mc-primary rounded" :class="[ werkbonModalStore.errors.handtekeningField ? 'maxi-error' : 'bg-white']"  @click="uitgevoerd ? null : werkbonModalStore.handtekeningModalOpen = true">
                                    <img v-if="uitgevoerd ? signature : werkbonModalStore.form.handtekening ? werkbonModalStore.form.handtekening : signature" :src="uitgevoerd ? signature : werkbonModalStore.form.handtekening ? werkbonModalStore.form.handtekening : signature" alt="" class="w-full h-full object-contain">
                                </div>
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.handtekeningField">{{ werkbonModalStore.errors.handtekeningField }}</span>
                            </div>
                        </div>
                        <div class="col-span-2 grid grid-cols-2 gap-2 content-start">
                            <div class="relative">
                                <label for="">{{ translationStore.t('werkbon.modal.te_ontvangen') }}</label>
                                <div class="relative flex items-center">
                                    <span class="absolute left-2 text-gray-400">€</span>
                                    <input type="text" :value="parseFloat(werkbonModalStore.form.teOntvangen)?.toFixed(2).replace('.', ',')" class="maxi-input pl-6" :class="[werkbonModalStore.errors.teOntvangen ? 'maxi-error' : '']" :placeholder="translationStore.t('werkbon.modal.te_ontvangen')" readonly>
                                </div>
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.teOntvangen">{{ werkbonModalStore.errors.teOntvangen }}</span>
                            </div>
                            <div class="relative">
                                <label for="">{{ translationStore.t('werkbon.modal.bedrag_ontvangen') }}</label>
                                <div class="relative flex items-center">
                                    <span class="absolute left-2 text-gray-400" :class="[werkbonModalStore.errors.bedragOntvangen ? 'text-red-600' : '']">€</span>
                                    <input 
                                    :disabled="uitgevoerd"
                                    type="text" v-model="werkbonModalStore.form.bedragOntvangen" 
                                    class="maxi-input pl-6" :class="[werkbonModalStore.errors.bedragOntvangen ? 'maxi-error' : '']" 
                                    :placeholder="translationStore.t('werkbon.modal.bedrag_ontvangen')"
                                    @input="werkbonModalStore.errors.bedragOntvangen = ''"
                                    >
                                </div>
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.bedragOntvangen">{{ werkbonModalStore.errors.bedragOntvangen }}</span>
                            </div>
                            <div class="col-span-2 grid grid-cols-4 gap-2">
                                
                                <div>
                                    <label for="">{{ translationStore.t('werkbon.modal.aankomst') }}</label>
                                    <TimeComponent round="down" key-value="aankomstWerf" :opdracht-id="apiStore.v2selectedOpdracht.opdrachtId" :readonly="true"/>
                                </div>
                                <div class="col-span-3">
                                    <label for="">{{ translationStore.t('werkbon.modal.vertrektijd_werf') }}</label>
                                    <TimeComponent round="up" key-value="vertrekWerf" :opdracht-id="apiStore.v2selectedOpdracht.opdrachtId"/>
                                </div>
                                
                            </div>
                            <div class="col-span-2 relative">
                                <label for="">{{ translationStore.t('werkbon.modal.status_opdracht') }}</label>
                                <div class="relative">
                                    <select :disabled="uitgevoerd" class="maxi-input pointer-events-auto" v-model="werkbonModalStore.form.statusOpdracht" :class="[werkbonModalStore.errors.statusOpdracht ? 'maxi-error' : '']">
                                        <option disabled value="">{{ translationStore.t('werkbon.modal.selecteer_status') }}</option>
                                        <option value=""></option>
                                        <option :value="item.Value" v-for="item in statusUitvoeringOpdracht" :key="item">
                                            {{ translationStore.lang === 'nl' ? item.NL : item.FR }}
                                        </option>
                                    </select>
                                    <span v-if="false && !werkbonModalStore.form.statusOpdracht" class="absolute top-1.5 left-2 text-black z-50 opacity-30 pointer-events-none">Selecteer de status</span>
                                </div>
                                
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.statusOpdracht">{{ werkbonModalStore.errors.statusOpdracht }}</span>
                            </div>
                            <div class="col-span-2 relative">
                                <label for="">{{ translationStore.t('werkbon.modal.reden_niet_uitgevoerd') }}</label>
                                <div class="relative">
                                    <select :disabled="uitgevoerd" class="maxi-input" v-model="werkbonModalStore.form.redenNietUitgevoerd" :class="[werkbonModalStore.errors.redenNietUitgevoerd ? 'maxi-error' : '']">
                                        <option disabled value="">{{ translationStore.t('werkbon.modal.selecteer_reden_niet_uitgevoerd') }}</option>
                                        <option value=""></option>
                                        <option :value="item.Value" v-for="item in redenNietUitgevoerd" :key="item">
                                            {{ translationStore.lang === 'nl' ? item.NL : item.FR }}
                                        </option>
                                    </select>
                                    <span v-if="false && !werkbonModalStore.form.redenNietUitgevoerd" class="absolute top-1.5 left-2 text-black z-50 opacity-30 pointer-events-none">Selecteer de reden niet uitgevoerd</span>
                                </div>
                                
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.redenNietUitgevoerd">{{ werkbonModalStore.errors.redenNietUitgevoerd }}</span>
                            </div>
                            <div class="col-span-2 relative">
                                <label for="">{{ translationStore.t('werkbon.modal.andere_reden') }}</label>
                                <input 
                                    :disabled="uitgevoerd"
                                    type="text" 
                                    v-model="werkbonModalStore.form.andereReden" 
                                    class="maxi-input" :class="[werkbonModalStore.errors.andereReden ? 'maxi-error' : '']" 
                                    :placeholder="translationStore.t('werkbon.modal.andere_reden_placeholder')"
                                    @input="werkbonModalStore.errors.andereReden = ''"
                                >
                                <span class="maxi-suberror" v-if="werkbonModalStore.errors.andereReden">{{ werkbonModalStore.errors.andereReden }}</span>
                            </div>  
                            
                        </div>
                    </div>
                    <div class="px-6 p-1 flex gap-4">
                        <button @click="werkbonModalStore.cancelSluiten" class="w-full maxi-button" :class="[
                            uitgevoerd ? 'bg-gray-600' : ''
                        ]">
                            {{ translationStore.t('werkbon.modal.cancel_en_sluiten') }}
                        </button>                        
                        <button v-if="!uitgevoerd" class="w-full maxi-button" @click="werkbonModalStore.v2opslaanSluiten">
                            {{ translationStore.t('werkbon.modal.opslaan_en_sluiten') }}
                        </button>
                        <button v-if="!uitgevoerd" :disabled="!apiStore.connection" class="w-full maxi-button" @click="werkbonModalStore.v2opslaanVerzenden" :class="[!apiStore.connection ? 'disabled-button' : '']">
                            {{ werkbonModalStore.processing ? translationStore.t('werkbon.modal.aan_het_versturen') : apiStore.connection ? translationStore.t('werkbon.modal.afsluiten_en_verzenden') : translationStore.t('werkbon.modal.geen_internet')}}
                        </button>
                    </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
