<script setup>
    
    //get the url
    const url = window.location.href;
    let status = 'Dev';
    //if url includes maxi.meyware.com
    if(url.includes('maxi.meyware.com'))
        status = 'Live';
    
</script>

<template>
    <div class="absolute top-0 right-0 z-[100000] bg-red-500 px-6 py-1 text-white rounded-bl-md text-sm">{{status}}: 5.1.2</div>
</template>