<script setup>
import PrimaryContainer from '@/components/PrimaryContainer.vue'
import { ref } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue'
import router from '@/router';
import { computed } from 'vue';
import { useApiStore } from '@/stores/apiStore';
import WerkbonModal from '@/components/WerkbonModal.vue';
import { useWerkbonModalStore } from '@/stores/werkbonModalStore';
import TimeComponent from '@/components/TimeComponent.vue'
import ReturnButton from '@/components/ReturnButton.vue';
import { asyncComputed } from '@vueuse/core';
import { getWerkbon, getTempWerkbon } from '@/services/external-object-service';
import { useTranslationStore } from '@/stores/translationStore';

const props = defineProps(['id'])

const werkbonModalStore = useWerkbonModalStore()
const apiStore = useApiStore()
const translationStore = useTranslationStore();

console.log('props.id', props.id)
apiStore.v2loadOpdracht(props.id);

const _werkbon = computed(() => apiStore.v2selectedOpdracht ?? {})
const _werkbonDetails = computed(() => apiStore.v2selectedOpdracht.details?.filter(item => item.opdrachtId == props.id) ?? [])

const openWerkbonModal = async () => {
    console.log('openWerkbonModal', _werkbon.value)
    await werkbonModalStore.loadSignature(_werkbon.value?.opdrachtId)
    await werkbonModalStore.v2loadWerkbonForm(_werkbon.value?.opdrachtId)
    werkbonModalStore.modalOpen = true
}

const openBijlagen = () => router.push({ name: 'external-objects', params: { opdrachtId: props.id } })

const hasWerkbon = asyncComputed(async () => {
    const werkbon = await getWerkbon(props.id);
    return !!werkbon?.eobase64;
});

const iframe = ref(null);
const showWerkbon = ref(false);
const showWerkbonLoader = ref(false);

const openWerkbon = async () => {
    showWerkbon.value = true;

    if (!hasWerkbon.value) {
        showWerkbonLoader.value = true;
        const tempWerkbon = await getTempWerkbon(props.id);
        iframe.value.src = `data:application/pdf;base64, ${tempWerkbon.eobase64}#toolbar=0&navpanes=0`;
        showWerkbonLoader.value = false;
    } else {
        const werkbon = await getWerkbon(props.id);
        iframe.value.src = `data:application/pdf;base64, ${werkbon.eobase64}#toolbar=0&navpanes=0`;
    }
};

</script>

<template>
    <div v-if="showWerkbon" class="absolute top-0 left-0 z-[10000] w-full h-full">
        <div class="bg-mc-primary w-full px-6 h-[5%] flex items-center justify-center">
            <button @click="showWerkbon = false" class="maxi-button">Opdrachtbon sluiten</button>
        </div>

            <div class="absolute top-0 left-0 bg-gray-800/50 w-full h-screen text-center text-xl py-10" v-if="showWerkbonLoader">
                <span class="bg-gray-900 px-8 py-2 text-white rounded">Opdrachtbon wordt geladen...</span>
            </div>
        <embed class="h-[95%]" ref="iframe" width='100%' height='100%' src='' />

    </div>
        <WerkbonModal />
  <AppLayout>    
    <template #left>
        <div class="header-button">{{ _werkbon?.opdrachtId }}</div>
    </template>
    <template #title>{{ translationStore.t('werkbon.titel') }}</template>
    <div class="flex pb-4 justify-between items-end gap-2">
        <div class="flex w-full gap-4 flex-1">
            <TimeComponent :label="translationStore.t('timeclocks.aankomst_werf')" round="down" key-value="aankomstWerf" :opdracht-id="id"/>
            <TimeComponent :label="translationStore.t('timeclocks.vertrek_werf')" round="up" key-value="vertrekWerf" :opdracht-id="id"/>
        </div>
        <div class="flex gap-2">
            <button @click="openWerkbonModal" class="maxi-button">{{ translationStore.t('werkbon.handtekening_button') }}</button>
            <a v-if="false" href="http://localhost:3000/api/app/rma/v2/opdrachtbon" class="maxi-button">{{ translationStore.t('werkbon.opdrachtbon_button') }}</a>
            <button v-if="hasWerkbon" @click="openWerkbon" class="maxi-button">
                {{ translationStore.t('werkbon.opdrachtbon_button') }}
            </button>
            <button v-else class="maxi-button" @click="openWerkbon">
                {{ translationStore.t('werkbon.voorlopige_opdrachtbon_button') }}
            </button>
            <button @click="openBijlagen" class="maxi-button">{{ translationStore.t('werkbon.bijlagen') }} ({{ _werkbon?.aantalAttachments ?? 0 }})</button>
        </div>
    </div>
<!--     {{ werkbonModalStore.form }}
 -->    <div class="flex flex-col gap-3">
      <div class="grid grid-cols-2 gap-3">
        <PrimaryContainer class="flex flex-col gap-6 leading-tight py-6" :uitgevoerd="_werkbon?.statusUitvoeringOpdracht === 'Uitgevoerd'">
          <div>
            <h3>{{ translationStore.t('werkbon.klant') }}</h3>
            <span class="font-medium">{{ _werkbon?.naam }}</span>
          </div>
          <div>
            <h3>{{ translationStore.t('werkbon.werfadres') }}</h3>
            <span class="font-medium">
                {{ _werkbon?.opdrachtStraat }} {{ _werkbon?.opdrachtHuisNr }}
                <template v-if="_werkbon?.opdrachtPostcode || _werkbon?.opdrachtGemeente">,</template>
                {{ _werkbon?.opdrachtPostcode }} {{ _werkbon?.opdrachtGemeente }}
            </span>
          </div>
          <div class="grid grid-cols-2">
            <div>
                <h3>{{ translationStore.t('werkbon.contact') }}</h3>
                <span class="font-medium">
                    {{ _werkbon?.opdrachtContactPersoon }}
                </span>
            </div>
            <div>
                <h3>{{ translationStore.t('werkbon.contact_telefoon') }}</h3>
                <span class="font-medium">
                    {{ _werkbon?.opdrachtTelNr }}
                </span>
            </div>
          </div>
        </PrimaryContainer>
        <PrimaryContainer class="flex flex-col gap-6 leading-tight py-6" :uitgevoerd="_werkbon?.statusUitvoeringOpdracht === 'Uitgevoerd'">
          <div>
            <h3>{{ translationStore.t('werkbon.facturatie') }}</h3>
            <span class="font-medium">
                {{ _werkbon?.factNaam }}
            </span>
          </div>
          <div>
            <h3>{{ translationStore.t('werkbon.factuuradres') }}</h3>
            <span class="font-medium">
                {{ _werkbon?.factStraat }} {{ _werkbon?.factHuisNr }}
                <template v-if="_werkbon?.factPostcode || _werkbon?.factGemeente">,</template>
                {{ _werkbon?.factPostcode }} {{ _werkbon?.factGemeente }}
            </span>
          </div>
          <div class="grid grid-cols-2">
            <div>
              <h3>{{ translationStore.t('werkbon.btw') }}</h3>
              <span class="font-medium">
                {{ _werkbon?.bTWnr }}
              </span>
            </div>
            <div>
              <h3>{{ translationStore.t('werkbon.contact_telefoon') }}</h3>
              <span class="font-medium">
                {{ _werkbon?.telNr }}
              </span>
            </div>
          </div>
        </PrimaryContainer>
      </div>
      <div class="grid grid-cols-2 gap-2 leading-tight">
        <PrimaryContainer class="py-6" :uitgevoerd="_werkbon?.statusUitvoeringOpdracht === 'Uitgevoerd'">
          <h3>{{ translationStore.t('werkbon.opdracht') }}</h3>
          <p class="font-medium">
            {{ _werkbon?.opdrachtOmschKort }}
          </p>
        </PrimaryContainer>
        <PrimaryContainer class="py-6" :uitgevoerd="_werkbon?.statusUitvoeringOpdracht === 'Uitgevoerd'">
          <h3>{{ translationStore.t('werkbon.opmerking') }}</h3>
          <p class="font-medium">
            {{ _werkbon?.opmerking }}
          </p>
        </PrimaryContainer>
      </div>
      <div class="text-sm">
        <div class="grid grid-cols-12 px-4 font-medium pb-2">
          <span class="col-span-4">{{ translationStore.t('werkbon.actie') }}</span>
          <span class="col-span-2 text-center">{{ translationStore.t('werkbon.aantal') }}</span>
          <span class="col-span-2 text-center">{{ translationStore.t('werkbon.unit') }}</span>
          <span class="col-span-2 text-center">{{ translationStore.t('werkbon.eenheidsprijs') }}</span>
          <span class="col-span-2 text-center">{{ translationStore.t('werkbon.subtotaal') }}</span>
        </div>
        <div>
          <div class="flex flex-col">
            <div v-for="(item, index) in _werkbonDetails" :key="item" class="grid grid-cols-12 py-2 px-4 w-full"
                :class="[
                    _werkbon?.statusUitvoeringOpdracht === 'Uitgevoerd' ? 
                        index % 2 == 0 ? 'bg-gray-400' : 'bg-gray-500' :
                        index % 2 == 0 ? 'bg-mc-primary' : 'bg-mc-secondary',
                    index == _werkbonDetails.length - 1 ? 'rounded-b-lg' : '',
                    index == 0 ? 'rounded-t-lg' : ''
                    ]"
            >
                <span class="col-span-4">{{ item?.omschrijving }}</span>
                <span class="col-span-2 text-center">{{ item?.hoeveelheid }}</span>
                <span class="col-span-2 text-center">{{ item?.eenheid }}</span>
                <span class="col-span-2 text-center">{{ item?.eenheidsprijs.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' }) }}</span>
                <span class="col-span-2 text-center">{{ (parseFloat(item?.hoeveelheid) * parseFloat(item?.eenheidsprijs)).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' }) }}</span>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </AppLayout>
  <ReturnButton>{{ translationStore.t('werkbon.terug_naar_werklijst') }}</ReturnButton>
</template>
