<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ArrowRightOnRectangleIcon, CalendarIcon } from '@heroicons/vue/24/outline'

// In your sidebar component and other components
import { useSidebarStore } from '@/stores/sidebarStore'
import { useApiStore } from '@/stores/apiStore';
import router from '@/router';
import { useTranslationStore } from '@/stores/translationStore';

const sidebarStore = useSidebarStore()
const apiStore = useApiStore()
const translationStore = useTranslationStore()

const openAgenda = () => {
  sidebarStore.close();
  router.push('/');
}

const logout = () => {
  sidebarStore.close();
    localStorage.removeItem('user');
    apiStore.authenticated = false;
    apiStore.authenticatedUser = null;
    //remove indexdb database
    indexedDB.deleteDatabase('myDatabase');
    indexedDB.deleteDatabase('staticDatabase');
    indexedDB.deleteDatabase('newDatabase');
  router.push('/login');
}

const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    translationStore.lang = lang;
}
</script>

<template>
  <TransitionRoot as="template" :show="sidebarStore.isOpen">
    <Dialog as="div" class="relative z-[10000]" @close="sidebarStore.close()">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-[-100%]"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-[-100%]"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <img src="/menu_image.png" alt="" class="w-full">
                <div class="flex h-full flex-col overflow-y-scroll bg-mc-primary py-6 shadow-xl">
                    
                  <div class="relative mt-6 flex-1 px-4 sm:px-6 flex flex-col gap-6">
                    <button @click="openAgenda" class="flex items-center gap-4 hover:bg-mc-secondary rounded-lg py-2 px-4 w-full">
                        <CalendarIcon class="h-10 w-10 text-white" aria-hidden="true" />
                        <span class="text-xl font-medium text-white">{{ translationStore.t('menu.agenda') }}</span>
                    </button>
                    <button @click="logout" class="flex items-center gap-4 hover:bg-mc-secondary rounded-lg py-2 px-4 w-full">
                        <ArrowRightOnRectangleIcon class="h-10 w-10 text-white" aria-hidden="true" />
                        <span class="text-xl font-medium text-white">{{ translationStore.t('menu.afmelden') }}</span>
                    </button>
                    <select class="maxi-input" :value="translationStore.lang" @change="changeLanguage($event.target.value)">
                        <option value="nl">Nederlands</option>
                        <option value="fr">Francais</option>
                    </select>
                </div>
                </div>
            </DialogPanel>
            </TransitionChild>
        </div>
        </div>
    </div>
    </Dialog>
</TransitionRoot>
</template>
