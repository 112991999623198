import { ref } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs';
import * as v2localCache from '@/services/local-service';
import { testFunction } from '@/services/api-service';

export const useApiStore = defineStore('api', () => {
    const connection = ref(true);

    const v2orders = ref([]);
    const v2agendaItems = ref([]);
    const v2selectedOpdracht = ref([]);
    const user = ref(null);
    const userId = ref(null);
    const v2localStorageSync = ref(null);
    const v2genCodes = ref([]);
    const lastSync = ref();
    const v2loadingInProgress = ref(false);

    const v2initialLoad = async () => {
        v2localStorageSync.value = await localStorage.getItem('lastSync');
        lastSync.value = v2localStorageSync.value;
        //testFunction();
    }

    
    const v2loadAgendaItems = async () => {
        user.value = await localStorage.getItem('user');
        userId.value = JSON.parse(user.value).werknemerId;
        v2agendaItems.value = await v2localCache.getLocalAgendaItems(userId.value);    
    };
    const v2loadOpdrachten = async (date, typeWagen, bestuurderId, begeleiderId) => v2orders.value = await v2localCache.getLocalOpdrachten(date, typeWagen, bestuurderId, begeleiderId);
    const v2loadOpdracht = async (opdrachtId) => v2selectedOpdracht.value = await v2localCache.getLocalOpdracht(opdrachtId);
    const v2loadLatestSync = async () => lastSync.value = await localStorage.getItem('lastSync');
    const v2updateLatestSync = async () => await localStorage.setItem('lastSync', dayjs().format('DD/MM/YYYY HH:mm:ss')) && v2loadLatestSync();
    const v2loadGencodes = async () => v2genCodes.value = await v2localCache.getLocalGenCodes();

    v2initialLoad();
    v2loadAgendaItems();
    v2loadLatestSync();
    v2loadGencodes();

    const connected = async () => connection.value = true;
    const disconnected = () => connection.value = false;
    
    return { 
        connection, 
        connected, 
        disconnected, 
        lastSync, 
        v2loadOpdrachten, 
        v2orders, 
        v2agendaItems, 
        v2loadOpdracht, 
        v2selectedOpdracht,
        v2loadAgendaItems,
        v2updateLatestSync,
        v2loadLatestSync,
        v2localStorageSync,
        v2loadGencodes,
        v2genCodes,
        v2initialLoad,
        v2loadingInProgress
    };
})



