import { bulkCreateOrUpdateTimeClock } from "@/services/time-service";
import { db, staticdb } from "../../db";
import { timestampToTime } from "./TimeUtils";
import { createOrUpdateExternalObject } from "@/services/external-object-service";
import { useApiStore } from './../stores/apiStore';
/*

const storeAgendaItems = async (data) => {
    const items = data['agendaItems'];
    await db.cache.add({
        type: 'agendaItems',
        data: items
    });
}*/

export const storeAgendaItems = async (data) => {
    const items = data['agendaItems'];    
    await db.cache.add({
        type: 'agendaItems',
        data: items
    });

    //v2
    for(let i = 0; i < items.length; i++) {
        const item = await db.agendaItems.get({
            begeleiderId: items[i]['begeleiderId'],
            bestuurderId: items[i]['bestuurderId'],
            typeWagen: items[i]['typeWagen'],
            datumUitvoering: items[i]['datumUitvoering']
        });
        if(!item)
            await db.agendaItems.add(items[i]);
    }
}

export const storeGenCodes = async (data) => {
    const items = data['genCodes'];
    await db.cache.add({
        type: 'genCodes',
        data: items
    });

    //v2
    for(let i = 0; i < items.length; i++) {
        //const item = await staticdb.genCodes.get({Code: items[i]['Code'], CodeGroup: items[i]['CodeGroup'], Description: items[i]['Description']});
        let itemCode = items[i]['Code'];
        let itemCodeGroup = items[i]['CodeGroup'];
        let itemValue = items[i]['Value'];
    
        // Use the where().equals() to query the compound index
        const item = await staticdb.genCodes
            .where('[CodeGroup+Code+Value]')
            .equals([itemCodeGroup, itemCode, itemValue])
            .first(); // Use first() to get the first matching item, if any
    
        if(!item) {
            await staticdb.genCodes.add(items[i]);
        }
    }
    loadGenCodes();
}

export const initialLoad = async (apiStore) => {
    await apiStore.initData(apiStore.authenticatedUser?.werknemerId)
    await apiStore.loadCache()
    //testFunction();
}

export const storeWorklist = async (data, date, typeWagen, bestuurderId, begeleiderId) => {
    const items = data['orders'];

    //v2
    for(let i = 0; i < items.length; i++) {
        console.log('storeWorklist', items[i]['opdrachtId']);
        const item = await db.worklists.get({opdrachtId: items[i]['opdrachtId']});
        if(!item)
            await db.worklists.add({...items[i], date, typeWagen, bestuurderId, begeleiderId});
        else {
            if(items[i]['statusUitvoeringOpdracht'] == 'Uitgevoerd') {
                await db.worklists.update(item.id, {...items[i], date, typeWagen, bestuurderId, begeleiderId});
            }
        }
    }

    let worklist = {
        date: date,
        typeWagen: typeWagen,
        bestuurderId: bestuurderId,
        begeleiderId: begeleiderId,
        data: items
    };

    //console.log('storeWorklist', worklist)
    if(date === null || typeWagen === null || bestuurderId === null || begeleiderId === null) {
        return;
    }
    await db.cache.add({
        type: 'worklist',
        data: worklist
    });

    const apiStore = useApiStore();
    await apiStore.loadCache();


}

export const storeOrder = async (item) => {
    //console.log('STOREOREDER', item);
    await bulkCreateOrUpdateTimeClock(item);
    
    // TODO: need to add puttimes
    /* const putTimes = {} */
    const data = await db.werkbonForm.get({opdrachtId: item['opdrachtId']});

    //console.log('storeOrder', data);


    /*
{
        opmerking: '',
        opmerkingKlant: '',
        naam: '',
        status: '',
        email: '',
        teOntvangen: '',
        bedragOntvangen: '',
        statusOpdracht: '',
        redenNietUitgevoerd: '',
        andereReden: '',
        handtekening: ''
    }
    */
    if(!data) {
        console.log('storeOrder', item);
        await db.werkbonForm.add({
            opdrachtId: item['opdrachtId'],

                opmerking: item['opmerkingUitvoering'],
                opmerkingKlant: item['opmerkingKlant'],
                naam: item['handtekeningNaam'],
                status: item['handtekeningStatus'],
                email: item['rMAEmail'],
                teOntvangen: item['bedragTeOntvangen'],
                bedragOntvangen: item['bedragOntvangen'] == 0 ? '' : item['bedragOntvangen'],
                statusOpdracht: item['statusUitvoeringOpdracht'],
                redenNietUitgevoerd: item['redenNietUitvoering'],
                andereReden: item['redenNietUitvoeringInfo'],
                handtekening: ''
            

            
        });

        console.log('timestampToTime', {
            opdrachtId: item['opdrachtId'],
            vertrek: timestampToTime(item['vertrek']),
            aankomstWerf: timestampToTime(item['aankomstWerf']),
            vertrekWerf: timestampToTime(item['vertrekWerf']),
            aankomst: timestampToTime(item['aankomst'])
        });

        await db.putTimes.add({
            opdrachtId: item['opdrachtId'],
            vertrek: timestampToTime(item['vertrek']),
            aankomstWerf: timestampToTime(item['aankomstWerf']),
            vertrekWerf: timestampToTime(item['vertrekWerf']),
            aankomst: timestampToTime(item['aankomst'])
        });

        /* console.log('test', {
            opmerking: item['opmerkingUitvoering'],
            opmerkingKlant: item['opmerkingKlant'],
            naam: item['handtekeningNaam'],
            status: item['handtekeningStatus'],
            email: item['rMAEmail'],
            teOntvangen: item['bedragTeOntvangen'],
            bedragOntvangen: item['bedragOntvangen'],
            statusOpdracht: item['statusUitvoeringOpdracht'],
            redenNietUitgevoerd: item['redenNietUitvoering'],
            andereReden: item['redenNietUitvoeringInfo'],
            handtekening: ''
        }) */
    } else if(item['statusUitvoeringOpdracht'] == 'Uitgevoerd') {
        await db.werkbonForm.update(data.id, {

                opmerking: item['opmerkingUitvoering'],
                opmerkingKlant: item['opmerkingKlant'],
                naam: item['handtekeningNaam'],
                status: item['handtekeningStatus'],
                email: item['rMAEmail'],
                teOntvangen: item['bedragTeOntvangen'],
                bedragOntvangen: item['bedragOntvangen'] == 0 ? '' : item['bedragOntvangen'],
                statusOpdracht: item['statusUitvoeringOpdracht'],
                redenNietUitgevoerd: item['redenNietUitvoering'],
                andereReden: item['redenNietUitvoeringInfo'],
                handtekening: ''
        
        });

        await db.putTimes.update(data.id, {
            vertrek: timestampToTime(item['vertrek']),
            aankomstWerf: timestampToTime(item['aankomstWerf']),
            vertrekWerf: timestampToTime(item['vertrekWerf']),
            aankomst: timestampToTime(item['aankomst'])
        });
    }
    
    console.log('statusOpdracht', item['statusUitvoeringOpdracht'], item);

    //console.log('storeOrder', item);
    await db.cache.add({
        type: 'order',
        data: item
    });

    //v2
    const order = await db.orders.get({opdrachtId: item['opdrachtId']});
    if(!order)
        await db.orders.add(item);
    else {
        if( item['statusUitvoeringOpdracht'] == 'Uitgevoerd') {
            await db.orders.update(order.id, item);
        }
    }

    const apiStore = useApiStore();
    await apiStore.loadCache();
}

export const storePutTimes = async (orderId, data) => {
    console.log('storePutTimes', orderId, data);

    const existingData = await db.putTimes.get({ opdrachtId: orderId });

    if (existingData) {
        const newData = {
            vertrek: data['vertrek'] || existingData['vertrek'],
            aankomstWerf: data['aankomstWerf'] || existingData['aankomstWerf'],
            vertrekWerf: data['vertrekWerf'] || existingData['vertrekWerf'],
            aankomst: data['aankomst'] || existingData['aankomst']
        };

        await db.putTimes.update(existingData.id, newData);
    } else {
        await db.putTimes.add({
            opdrachtId: orderId,
            vertrek: data['vertrek'],
            aankomstWerf: data['aankomstWerf'],
            vertrekWerf: data['vertrekWerf'],
            aankomst: data['aankomst']
        });
    }

    const apiStore = useApiStore();
    await apiStore.loadCache();
};

export const storeOrderDetails = async (data) => {
    const items = data['orderDetailItems'];
    await db.cache.add({
        type: 'orderDetails',
        data: items
    });

    //v2
    for(let i = 0; i < items.length; i++) {
        const item = await db.orderDetails.get({opdrachtId: items[i]['opdrachtId']});
        if(!item)
            await db.orderDetails.add(items[i]);
    }
}

export const storeExternalObjects = async (data) => {
    const items = data['externalObjectItems'];

    for(let i = 0; i < items.length; i++) {
        //console.log('items[i]', items[i]);
        console.log('storeExternalObjects', items[i])
        createOrUpdateExternalObject(items[i]);
        /*const data = await db.externalObjects.get({compoundIndex: items[i]['referenceId'] + '_' + items[i]['eOId']});

        if(data) {
            await db.externalObjects.update(data.id, {
                EOBase64: items[i]['eOBase64'],
                data: items[i]
            });
        } else {
            await db.externalObjects.add({
                compoundIndex: items[i]['referenceId'] + '_' + items[i]['eOId'],
                opdrachtId: items[i]['referenceId'],
                EOId: items[i]['eOId'],
                EOBase64: items[i]['eOBase64'],
                data: items[i]
            });
        }*/

        /*await db.externalObjects.add({
            type: 'externalObjects',
            data: items[i]
        });*/
    }

    await db.cache.add({
        type: 'externalObjects',
        data: items
    });
}

export const storeWerkbonForm = async (opdrachtId, form) => {
    try {
        const data = await db.werkbonForm.get({opdrachtId: opdrachtId});
        const eodata = await db.externalObjects.get({compoundIndex: opdrachtId + '_signature'});
        
        //format bedragOntvangen to number
        if(form['bedragOntvangen'] !== '') {
            console.log('bedragOntvangen', form['bedragOntvangen'])
            if(form['bedragOntvangen'] !== null)
                form['bedragOntvangen'] = parseFloat(form['bedragOntvangen'].replace(',', '.'));
        }
        

        console.log('storeWerkbonForm', 'data', data);
        if(data) {
            console.log('storeWerkbonForm', 'update', {
                ...form,
                statusOpdracht: null,
            })
            await db.werkbonForm.update(data.id, {
                ...form,
                statusOpdracht: null,
            });
    
        } else {
            console.log('storeWerkbonForm', 'add', form)
            await db.werkbonForm.add({
                opdrachtId: opdrachtId,
                ...form,
                statusOpdracht: null,
            });
        }
    
        if(eodata) {
    
            console.log('storeSignature', 'update', form)
            await db.externalObjects.update(eodata.id, {
                eoBase64: form['handtekening'].split(',')[1],
            });
    
        } else {
            console.log('storeSignature', 'add', form)
            await db.externalObjects.add({
                compoundIndex: opdrachtId + '_signature',
                eoSubType: 'SIGNATURE',
                eoType: 'IMAGE',
                eoFile: 'signature.jpg',
                eoOmschrijving: 'Handtekening',
                eoOrigin: 'RMA',
                referenceType: 'OPDRACHT',
                referenceId: opdrachtId,
                eoBase64: form['handtekening'].split(',')[1],
            });
        }
    
        return;
    } catch (error) {
        console.log('storeWerkbonForm', error);
    }
    
}

export const loadWerkbonForm = async (opdrachtId) => {
    const data = await db.werkbonForm.get({opdrachtId: opdrachtId});
    if(!data) return null;
    return data;
}

export const loadGenCodes = async () => {
    const data = await staticdb.genCodes.toArray();
    return data;
}

export const loadAgendaItems = async () => {
    const data = await db.agendaItems.toArray();
    return data;
}

export const loadWorklists = async () => {
    const data = await db.worklists.toArray();
    console.log('loadWorklists', data);
    return data;
}

export const loadOrders = async () => {
    const data = await db.orders.toArray();
    return data;
}

export const loadOrderDetails = async () => {
    const data = await db.orderDetails.toArray();
    return data;
}

export const loadExternalObjects = async () => {
    const data = await db.cache.get({type: 'externalObjects'});
    return data['data'];
}

export const loadPutTimes = async (orderId) => {
    const data = await db.putTimes.get({opdrachtId: orderId});
    return data;
}

export const updateWerkbonForm = async (opdrachtId, form) => {

    const data = await db.werkbonForm.get({opdrachtId: opdrachtId});

    if(data) {
        await db.werkbonForm.update(data.id, {
            ...form
        });
    }
}

export const getRmaEmail = async () => {
    const data = await staticdb.genCodes.get({CodeGroup: 'RMA_EMAIL'});
    console.log('getRmaEmail', data);
    return data['Value'];
}