<script setup>
import router from '@/router';
import { computed } from 'vue'
import TimeComponent from './TimeComponent.vue';
import { useApiStore } from '@/stores/apiStore';
import { useTranslationStore } from '@/stores/translationStore';

const apiStore = useApiStore()
const translationStore = useTranslationStore()

const props = defineProps(['item'])

const openWerkbon = () => {
    apiStore.v2loadOpdracht(props.item.opdrachtId);
    //router.push('/werkbon')
    router.push({ name: 'werkbon', params: { id: props.item.opdrachtId } })
}

const bgColor = computed(() => {
    if (props.item.statusUitvoeringOpdracht == 'Gestart') return 'bg-mc-secondary'
    if (props.item.statusUitvoeringOpdracht == 'Uitgevoerd') return 'bg-gray-400'
    if (props.item.statusUitvoeringOpdracht == 'Niet uitgevoerd') return 'bg-red-600'

  if (props.item.statusUitvoeringOpdracht == null) return 'bg-mc-primary'

  return 'bg-mc-primary'
})
</script>

<template>
  <button @click="openWerkbon" class="relative py-1">
    <div
      :class="[bgColor, item.swtEersteWerk ? 'border-4 border-red-600' : '']"
      class="py-6 px-4 flex flex-col gap-6 rounded-lg text-left text-sm min-h-[200px] relative"
    >
      <div class="grid grid-cols-12">
        <div>
          <span>{{ item.volgr }}</span>
        </div>
        <div class="col-span-4 flex flex-col">
          <span class="font-medium">{{ item.naam }}</span>
          <span>{{ item.opdrachtContactPersoon }}</span>
          <span>{{ item.opdrachtStraat }} {{item.opdrachtHuisNr}}, {{ item.opdrachtPostcode }} {{ item.opdrachtGemeente }}</span>
        </div>
        <div class="col-span-2 flex flex-col">
          <span>{{ item.opdrachtTelNr }}</span>
          <span>{{ item.telNr }}</span>
        </div>
        <div class="col-span-2 flex flex-col">
          <span>{{ item.opdrachtId }}</span>
          <span>{{ item.voorkeurTijdstip }}</span>
          <span>{{ item.statusUitvoeringOpdracht }}</span>
          <span>{{ item.aantalAttachments }} {{ translationStore.t('werklijst_item.bestanden') }}</span>
        </div>
        <div @click.stop class="col-span-3 flex flex-col justify-center h-full">
            <TimeComponent :label="translationStore.t('timeclocks.vertrek_depot')" position="left" round="down" key-value="vertrek" :opdracht-id="item.opdrachtId"/>
            <TimeComponent :label="translationStore.t('timeclocks.aankomst_werf')" position="left" round="down" key-value="aankomstWerf" :opdracht-id="item.opdrachtId"/>
            <TimeComponent :label="translationStore.t('timeclocks.vertrek_werf')" position="left" round="up" key-value="vertrekWerf" :opdracht-id="item.opdrachtId"/>
            <TimeComponent :label="translationStore.t('timeclocks.aankomst_depot')" position="left" round="up" key-value="aankomst" :opdracht-id="item.opdrachtId"/>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <div>
          <h3 class="font-medium">{{ translationStore.t('werklijst_item.opdracht') }}</h3>
          <p>{{ item.opdrachtOmschKort }}</p>
        </div>
        <div>
          <h3 class="font-medium">{{ translationStore.t('werklijst_item.omschrijving') }}</h3>
          <p>{{ item.opmerking }}</p>
        </div>
      </div>
    </div>
  </button>
</template>
