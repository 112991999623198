<script setup>
    import AppLayout from '@/layouts/AppLayout.vue';
    import ExternalObject from '@/components/ExternalObject.vue';
    import WerkbonModal from '@/components/WerkbonModal.vue';
    import ExternalObjectsModal from '@/components/ExternalObjectModal.vue';
    import ReturnButton from '@/components/ReturnButton.vue';
    import { onMounted, reactive, ref } from 'vue';
    import { useLoadingStore } from '@/stores/loadingStore';
    import { useExternalObjectsModalStore } from '@/stores/externalobjectModalStore';
    import PostModal from '@/components/newEoModal/postModal.vue';
    import imageCompression from 'browser-image-compression';
    import UpdateModal from '@/components/newEoModal/updateModal.vue';
import { useTranslationStore } from '@/stores/translationStore';

    const props = defineProps(['opdrachtId']);

    const loadingStore = useLoadingStore();
    const externalobjectModalStore = useExternalObjectsModalStore();
    const translationStore = useTranslationStore();

    const pictureInput = ref(null);
    const uploadInput = ref(null);
    const photoUrl = ref('');

    loadingStore.loading = true;

    const addPhotoForm = reactive({
        photo: null
    });

    const sizes = ref([]);

    onMounted(async () => {
        //externalObjects.value = await getLocalExternalObjects(props.id);
        await externalobjectModalStore.updateEoObjects(props.opdrachtId);

        //get file size of eo_objects just from the base64 strings

        const fileSizes = externalobjectModalStore.eo_objects.map((eo) => {
            //console.log('eo', eo);
            return eo.eobase64.length * (3/4) - 2;
        });

        const sumFileSizes = fileSizes.reduce((a, b) => a + b, 0);

        console.log('fileSizes', sumFileSizes);
        loadingStore.loading = false;
        //externalObjects.value = externalObjectsFromApi.externalObjectItems;
    });

    const selectPicture = () => {
        pictureInput.value.click();
    }

    const uploadPicture = () => {
        uploadInput.value.click();
    }

    const handleFileInputChange = async (event) => {
        sizes.value = [];
        const imageFile = event.target.files[0];
            console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
            sizes.value.push({original: imageFile.size / 1024 / 1024 + ' MB'})

            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            }
            try {
                const compressedFile = await imageCompression(imageFile, options);
                console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
                console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
                sizes.value.push({compressed: compressedFile.size / 1024 / 1024 + ' MB'})
                

                console.log('compressedFile', compressedFile);
                addPhotoForm.blob = compressedFile;
                photoUrl.value = URL.createObjectURL(compressedFile);

                externalobjectModalStore.openPost(compressedFile);
            } catch (error) {
                console.log(error);
            }
    }
</script>

<template>
    <ExternalObjectsModal v-if="false"/>
    <PostModal :opdrachtId="opdrachtId"/>
    <UpdateModal :opdrachtId="opdrachtId"/>
    <AppLayout>
        <template #title>{{ translationStore.t('eo.titel') }}</template>
        <div class="flex justify-end py-4 gap-2">
            <RouterLink v-if="false" :to="{
                name: 'camera',
                params: { id: props.opdrachtId }
            }" class="maxi-button">{{ translationStore.t('eo.foto_toevoegen') }}</RouterLink>
            <button @click="uploadPicture" class="maxi-button">{{ translationStore.t('eo.foto_uploaden') }}</button>
            <button @click="selectPicture" class="maxi-button">{{ translationStore.t('eo.foto_toevoegen') }}</button>
            <input class="hidden" type="file" ref="pictureInput" accept="image/*" capture="environment" @change="handleFileInputChange"/>
            <input class="hidden" type="file" ref="uploadInput" accept="image/*" @change="handleFileInputChange"/>
        </div>
        <div class="grid grid-cols-4 gap-4">
            <ExternalObject v-for="externalObject in externalobjectModalStore.eo_objects" :key="externalObject" :externalObject="externalObject"></ExternalObject>
        </div>
        
    </AppLayout>
    <WerkbonModal />
    <ReturnButton>{{ translationStore.t('eo.terug_button') }}</ReturnButton>
</template>