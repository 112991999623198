import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { useAgendaStore } from './agendaStore'

export const useDateStore = defineStore('date', () => {
    const agendaStore = useAgendaStore()
    const date = ref(dayjs())

    const formattedDate = computed(() => date.value.format('DD/MM/YYYY'));
    const apiFormattedDate = computed(() => date.value.format('YYYY-MM-DD'));
    const refDatum = computed(() => date.value.format('YYYY-MM-DD'))
    // const refDatum = computed(() => '2024-04-08')
    // const refDatum = computed(() => '2023-12-20')
    // const refDatum = computed(() => '2024-02-23')

    const fakeDate = computed(() => dayjs(date.value).isSame(dayjs(), 'day') ? refDatum.value : refDatumYesterday.value);
    const refDatumYesterday = computed(() => dayjs(refDatum.value).subtract(1, 'day').format('YYYY-MM-DD'))

    const canIncrement = computed(() => !dayjs(date.value).isSame(dayjs(), 'day'));
    const canDecrement = computed(() => !dayjs(date.value).isSame(dayjs().subtract(1, 'day'), 'day'));

    function increment() {
        agendaStore.selected = null;
        if (!dayjs(date.value).isSame(dayjs(), 'day')) {
            date.value = dayjs(date.value).add(1, 'day');
        }
    }   

    function decrement() {
        agendaStore.selected = null;
        if (!dayjs(date.value).isSame(dayjs().subtract(1, 'day'), 'day')) {
            date.value = dayjs(date.value).subtract(1, 'day');
        }
    }
    
    return { 
        date, 
        increment, 
        decrement, 
        formattedDate, 
        canIncrement, 
        canDecrement, 
        refDatum, 
        refDatumYesterday, 
        fakeDate, 
        apiFormattedDate 
    }
})
