import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAgendaStore = defineStore('agenda', () => {
    const selected = ref(null);

    const select = (agenda) => {
        selected.value = agenda;
    };

    return { selected, select };
});
