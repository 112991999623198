<script setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
//import { useWerkbonModalStore } from '@/stores/werkbonModalStore';
import { useExternalObjectsModalStore } from '@/stores/externalobjectModalStore';
import { useApiStore } from '@/stores/apiStore';
import { onMounted, computed, reactive, watch, ref } from 'vue'
import { newCreateApiExternalObject } from '@/services/external-object-service';
import { useTranslationStore } from '@/stores/translationStore';

//const werkbonModalStore = useWerkbonModalStore();
const apiStore = useApiStore();
const externalObjectsModalStore = useExternalObjectsModalStore();
const translationStore = useTranslationStore();
const isDisabled = ref(false);

const props = defineProps(['opdrachtId']);

const postForm = reactive({
    opdrachtId: props.opdrachtId,
    description: '',
    eovolgorde: 0,
})

onMounted(() => {
    console.log(externalObjectsModalStore.newModalExternalObject);
});

const saveExternalObject = async () => {
    if(!apiStore.connection) return;
    if(isDisabled.value) return;
    isDisabled.value = true;
    await newCreateApiExternalObject(postForm);
    await externalObjectsModalStore.updateEoObjects(props.opdrachtId)

    postForm.value = {
        blob: null,
        photo: null,
        description: '',
        eovolgorde: 0
    }
    externalObjectsModalStore.postModal = false;
    isDisabled.value = false;
}

const downloadBase64File = async () => {
    let base64String;
    let linkSource;
    if(externalObjectsModalStore.modalExternalObject.extension.includes('pdf')) {
        base64String = externalObjectsModalStore.modalExternalObject.eobase64;
        linkSource = base64String;
    } else {
        linkSource = externalObjectsModalStore.modalExternalObject.image
    }
    
    const downloadLink = document.createElement("a");
    const fileName = externalObjectsModalStore.modalExternalObject.eofile;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

watch(
    () => externalObjectsModalStore.newModalExternalObject,
    (externalObject) => {
        postForm.blob = externalObject.blob;
        postForm.photo = convertBlobToFile(externalObject.blob);
        postForm.description = '';
        console.log(externalObject)
        postForm.eovolgorde = externalObjectsModalStore.getNextVolgorde();
    }
);

const convertBlobToFile = (blob) => {
    const timestamp = new Date().toISOString().replace(/[^0-9]/g, '');
    const fileName = `photo_${timestamp}.jpg`;

    const file = new File([blob], fileName, { type: 'image/jpeg' });

    return file;
}

const imageUrl = computed(() => {
    return URL.createObjectURL(externalObjectsModalStore.newModalExternalObject.blob);
})

</script>

<template>
    <TransitionRoot as="template" :show="externalObjectsModalStore.postModal">
        <Dialog as="div" class="relative z-[10000]" @close="externalObjectsModalStore.postModal = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-[10000] w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="relative transform overflow-hidden rounded-lg bg-mc-primary text-left shadow-xl transition-all sm:my-8 sm:w-full mx-12">
                <button @click="externalObjectsModalStore.postModal = false" class="right-5 top-5 absolute outline-none">
                    <XMarkIcon class="h-10 w-10 text-white" aria-hidden="true" />
                </button>
                <div class="flex flex-col text-white w-full gap-2 pt-10">
                    <div class="px-6 flex gap-4">
                        <div class="flex flex-col gap-3 w-full">
                            <h3>{{ translationStore.t('eo.foto') }}</h3>
                            <div class="bg-mc-secondary w-full rounded-lg" @click="downloadBase64File">
                                <img :src="imageUrl" alt="" class="h-64 object-contain mx-auto">
                            </div>
                            
                        </div>
                        <div class="flex flex-col gap-3 w-full">
                            <h3>{{ translationStore.t('eo.beschrijving') }}</h3>
                            <textarea cols="30" rows="10" class="rounded-lg maxi-input" v-model="postForm.description"></textarea>
                        </div>
                    </div>
                    <div class="p-6 flex gap-4">
                        <div class="flex w-full items-end">
                            <div class="w-full">
                                <h3>{{ translationStore.t('eo.gemaakt_op') }}</h3>
                                <span>
                                    {{ new Date(externalObjectsModalStore.newModalExternalObject.blob.lastModified).toLocaleDateString() }}
                                </span>
                            </div>
                            <div class="w-full">
                                <h3>{{ translationStore.t('eo.gemaakt_door') }}</h3>
                                <input type="number" class="rounded-lg maxi-input" v-model="postForm.eovolgorde" />
                            </div>
                        </div>
                        <div class="w-full flex gap-2">
                            <button class="w-full maxi-button" @click="externalObjectsModalStore.postModal = false">
                                {{ translationStore.t('eo.cancel') }}
                            </button>
                            <button class="w-full maxi-button" :disabled="!apiStore.connection" :class="[apiStore.connection ? '' : 'disabled-button']" @click="saveExternalObject()">
                                {{ apiStore.connection ? translationStore.t('eo.opslaan') : translationStore.t('eo.geen_verbinding') }} 
                            </button>
                        </div>
                        
                    </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>