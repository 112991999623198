<script setup>
import { useRouter } from 'vue-router';
import AppMenu from '@/components/AppMenu.vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import { useSidebarStore } from '@/stores/sidebarStore'
import { useApiStore } from '@/stores/apiStore';
import { onBeforeMount } from 'vue'
import VersionIndicator from '@/components/VersionIndicator.vue';
import { testFunction } from '@/services/api-service';
import { useTranslationStore } from '@/stores/translationStore';

const router = useRouter(); // Get access to the router instance
const apiStore = useApiStore()
const sidebarStore = useSidebarStore()
const translationStore = useTranslationStore();

onBeforeMount(() => {
    if(localStorage.getItem('user')) {
        apiStore.authenticated = true;
        apiStore.authenticatedUser = JSON.parse(localStorage.getItem('user'));
        if(!localStorage.getItem('initialized')) {
            //apiStore.initData(apiStore.authenticatedUser.werknemerId);
            localStorage.setItem('initialized', true);
        }
    }
    if (!apiStore.authenticated) {
        router.push('/login'); // Redirect using Vue Router
    }
})

const reInitData = async () => {
    /* await apiStore.initData(apiStore.authenticatedUser.werknemerId);
    await apiStore.loadCache(); */
    testFunction();

    //if route contains werkbon
    if(router.currentRoute.value.name == 'werkbon' || router.currentRoute.value.name == 'werklijst') {
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }
    
}
</script>


<template>
  <!--
      This example requires updating your template:
  
      ```
      <html class="h-full bg-gray-100">
      <body class="h-full">
      ```
    -->
  <AppMenu />
  <VersionIndicator />
  <div class="min-h-full">
    <div class="fixed top-0 w-full z-[1000]">
        <div v-if="!apiStore.connection" class="bg-gray-700 text-white text-center py-2">
            {{ translationStore.t('algemeen.no_connection') }}
        </div>
        <div class=" shadow-sm text-white" :class="[apiStore.connection ? 'bg-mc-primary' : 'bg-gray-500']">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16">
            <div class="flex justify-between items-center w-full">
                <div class="flex flex-shrink-0 items-center gap-4">
                <button @click="sidebarStore.open()">
                    <Bars3Icon class="h-10 w-10 text-white" aria-hidden="true" />
                </button>
                <h1 class="text-xl font-medium">
                    <slot name="title" />
                </h1>
                <slot name="left" />
                </div>
                <div class="flex gap-2">
                    <slot name="middle" />
                </div>
                <div>
                    <slot name="right" />
                    <div v-if="apiStore.connection" class="flex items-center gap-2">
                        <span>{{ apiStore.lastSync }}</span>
                        <button v-if="!apiStore.v2loadingInProgress" @click="reInitData" class="bg-mc-secondary flex items-center rounded py-2 px-4">
                            {{ translationStore.t('algemeen.data_ophalen') }}
                        </button>
                        <div v-else class="bg-mc-secondary flex items-center rounded py-2 px-4">
                            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2.5"></path>
                            </svg>
                        </div>
                    </div>

                </div>
                
            </div>
            </div>
        </div>
        </div>
    </div>
    <div class="mx-auto max-w-7xl py-10 px-4 sm:px-6" :class="[
        apiStore.connection ? 'mt-10' : 'mt-20'
    ]">
      <slot />
    </div>
  </div>
</template>
