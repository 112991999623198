import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getApiExternalObjects } from './../services/external-object-service';

export const useExternalObjectsModalStore = defineStore('externalobjectmodal', () => {
    const postModal = ref(false);
    const updateModal = ref(false);

    const modalExternalObject = ref({});

    const newModalExternalObject = ref({});

    const eo_objects = ref([]);

    const highestEoVolgorde = ref(0);
   
    async function openPost(blob) {
        console.log("open modal");

        newModalExternalObject.value = {
            blob: blob
        };

        console.log(newModalExternalObject.value);
        postModal.value = true;
    }

    async function openUpdate(externalObject) {
        console.log("open modal");
        
        modalExternalObject.value = externalObject;
        updateModal.value = true;
    }

    async function updateEoObjects (orderId) {
        const externalObjectsFromApi = await getApiExternalObjects(orderId);
        const filteredExternalObjects = externalObjectsFromApi.externalobjectitems.filter(eo => eo.eosubtype !== 'SIGNATURE' && eo.eosubtype !== 'WERKBON');
        console.log('filteredExternalObjects', externalObjectsFromApi);

        highestEoVolgorde.value = 0;
        for (let i = 0; i < externalObjectsFromApi.externalobjectitems.length; i++) {
            if (externalObjectsFromApi.externalobjectitems[i].eovolgorde > highestEoVolgorde.value) {
                console.log('volgorde', externalObjectsFromApi.externalobjectitems[i])
                if(externalObjectsFromApi.externalobjectitems[i].eovolgorde !== 800 && externalObjectsFromApi.externalobjectitems[i].eovolgorde !== 900 && externalObjectsFromApi.externalobjectitems[i].eovolgorde !== 1000)
                    highestEoVolgorde.value = externalObjectsFromApi.externalobjectitems[i].eovolgorde;
            }
        }

        eo_objects.value = filteredExternalObjects;
    }

    function getNextVolgorde() {
        //round down to the closest 10 and add 10
        return Math.floor(highestEoVolgorde.value / 10) * 10 + 10;
    }
    
   /*  function opslaanSluiten() {
        storeWerkbonForm(opdrachtId.value, form.value);
        modalOpen.value = false;
    }

    function cancelSluiten() {
        modalOpen.value = false;
    }

    function opslaanVerzenden() {
        putWerkbonForm(opdrachtId.value, form.value);
        //modalOpen.value = false;
    } */
    
    return { postModal, updateModal, openPost, openUpdate, modalExternalObject, eo_objects, newModalExternalObject, updateEoObjects, highestEoVolgorde, getNextVolgorde }
})
