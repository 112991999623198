<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useWerkbonModalStore } from '@/stores/werkbonModalStore';


const werkbonModalStore = useWerkbonModalStore();

const signaturePad = ref(null);

const saveSignature = () => {
    /*
const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    */

    const { isEmpty, data } = signaturePad.value.saveSignature(
        'image/jpeg'
    );
    console.log(isEmpty);
    console.log(data);
    if(!isEmpty) {
        werkbonModalStore.form.handtekening = data;
        werkbonModalStore.errors.handtekeningField = '';
        werkbonModalStore.errors.status = '';
        werkbonModalStore.form.status = 'Ondertekend';
        closeModal();
    } else {
        werkbonModalStore.form.handtekening = '';
        werkbonModalStore.errors.handtekeningField = '';
        werkbonModalStore.errors.status = '';
        //werkbonModalStore.errors.handtekening = 'Er is geen handtekening ingevuld';
        closeModal();

    }
    
}

const clearSignature = () => {
    signaturePad.value.clearSignature();
}

const closeModal = () => {
    werkbonModalStore.handtekeningModalOpen = false
}
</script>

<template>
    <TransitionRoot as="template" :show="werkbonModalStore.handtekeningModalOpen">
      <Dialog as="div" class="relative z-[10000]" @close="closeModal">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
  
        <div class="fixed inset-0 z-[10000] w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-mc-primary text-left shadow-xl transition-all sm:my-8 sm:w-full mx-12">
                <button @click="closeModal" class="right-5 top-5 absolute outline-none">
                    <XMarkIcon class="h-10 w-10 text-white" aria-hidden="true" />
                </button>
                <div class="flex flex-col text-white w-full gap-2 py-4">
                    <div class="px-6">
                        <div v-if="werkbonModalStore.errors.handtekening" class="bg-red-100 border border-red-600 rounded-lg text-red-800 p-4 my-4">
                            {{ werkbonModalStore.errors.handtekening }}
                        </div>
                        <VueSignaturePad height="500px" ref="signaturePad" 
                            :options="{
                                penColor: 'rgb(0, 0, 0)',
                                backgroundColor: 'rgb(255, 255, 255)',
                                minWidth: 3,
                                maxWidth: 5,
                            }"
                        />
                    </div>

                    <div class="p-6 flex gap-4">
                        <button @click="saveSignature" class="w-full maxi-button">
                            Save
                        </button>
                        <button class="w-full maxi-button" @click="clearSignature">
                            Clear
                        </button>
                        <button class="w-full maxi-button bg-mc-tertiary" @click="closeModal">
                            Cancel
                        </button>
                    </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>