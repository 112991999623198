import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useApiStore } from './apiStore';
import { prepareBase64ExternalObject } from '@/functions/uploadExternalObject';
import { getSignature } from './../services/external-object-service';
import * as v2localCache from '@/services/local-service';
import { getFullOrder, v2putWerkbonForm } from '@/services/api-service';

export const useWerkbonModalStore = defineStore('werkbonmodal', () => {
    const modalOpen = ref(false);
    const handtekeningModalOpen = ref(false);
    const cachedOpdrachtStatus = ref(null);
    const signature = ref(null);
    const processing = ref(false);
   
    const opdrachtId = ref(null);

    const form = ref({
        general: '',
        opmerking: '',
        opmerkingKlant: '',
        naam: '',
        status: '',
        email: '',
        teOntvangen: '',
        bedragOntvangen: '',
        statusOpdracht: '',
        redenNietUitgevoerd: '',
        andereReden: '',
        handtekening: ''
    })

    const errors = ref({
        opmerking: '',
        opmerkingKlant: '',
        naam: '',
        status: '',
        email: '',
        teOntvangen: '',
        bedragOntvangen: '',
        statusOpdracht: '',
        redenNietUitgevoerd: '',
        andereReden: '',
        handtekening: ''
    });


    

    // V2: Loading data from local cache in the form parameter of the store
    async function v2loadWerkbonForm(opdrachtId) {
        try {
            console.log('Loading werkbon');
            const apiStore = useApiStore();
            console.log(apiStore.v2selectedOpdracht.bedragTeOntvangen)
    
            form.value = {
                opmerking: apiStore.v2selectedOpdracht.opmerkingUitvoering || '',
                opmerkingKlant: apiStore.v2selectedOpdracht.opmerkingKlant || '',
                naam: apiStore.v2selectedOpdracht.opdrachtContactPersoon || '',
                status: apiStore.v2selectedOpdracht.handtekeningStatus || '',
                email: apiStore.v2selectedOpdracht.email || '',
                teOntvangen: apiStore.v2selectedOpdracht.bedragTeOntvangen,
                bedragOntvangen: apiStore.v2selectedOpdracht.bedragOntvangen,
                statusOpdracht: apiStore.v2selectedOpdracht.statusUitvoeringOpdracht || '',
                redenNietUitgevoerd: apiStore.v2selectedOpdracht.redenNietUitvoering || '',
                andereReden: apiStore.v2selectedOpdracht.redenNietUitvoeringInfo || '',
                handtekening: ''
            };
    
            errors.value = {
                opmerking: '',
                opmerkingKlant: '',
                naam: '',
                status: '',
                email: '',
                teOntvangen: '',
                bedragOntvangen: '',
                statusOpdracht: '',
                redenNietUitgevoerd: '',
                andereReden: '',
                handtekening: ''
            };
    
            const localOpdracht = await v2localCache.getLocalOpdracht(opdrachtId);
            if (localOpdracht.statusUitvoeringOpdracht === 'Uitgevoerd') {
                cachedOpdrachtStatus.value = localOpdracht.statusUitvoeringOpdracht;
                Object.assign(form.value, {
                    opmerking: localOpdracht.opmerkingUitvoering,
                    opmerkingKlant: localOpdracht.opmerkingKlant,
                    naam: localOpdracht.handtekeningNaam,
                    status: localOpdracht.handtekeningStatus,
                    email: localOpdracht.rMAEmail,
                    teOntvangen: localOpdracht.bedragTeOntvangen,
                    bedragOntvangen: localOpdracht.bedragOntvangen,
                    vertrektijdWerk: localOpdracht.vertrektijdWerk,
                    statusOpdracht: localOpdracht.statusUitvoeringOpdracht,
                    redenNietUitgevoerd: localOpdracht.redenNietUitvoering,
                    andereReden: localOpdracht.redenNietUitvoeringInfo,
                    handtekening: localOpdracht.handtekening
                });
            } else {
                const localWerkbonForm = await v2localCache.getLocalWerkbonForm(opdrachtId);
                if (localWerkbonForm) {
                    console.log('Local werkbonForm', localWerkbonForm);
                    Object.assign(form.value, localWerkbonForm);
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    }    

    // V2 function to save and close the werkbonModal
    async function v2opslaanSluiten() {
        const apiStore = useApiStore();
        const opdrachtId = apiStore.v2selectedOpdracht.opdrachtId;

        try {
            v2localCache.upsertWerkbonForm(opdrachtId, form.value);
    
            const apiStore = useApiStore();
            if (apiStore.connection) {
                const response = await v2putWerkbonForm(opdrachtId, form.value, true);
                console.log('response', response);
    
                if (!response.includes('NOK')) {
                    console.log('success');
                }
            }
            modalOpen.value = false;
        } catch (error) {
            console.error('error', error);
        }
    }
    
    const cancelSluiten = () => modalOpen.value = false;
    // V2 function to save and send the werkbonModal to the api
    async function v2opslaanVerzenden() {
        if (processing.value) return;
        processing.value = true;
    
        try {
            const apiStore = useApiStore();
            const opdrachtId = apiStore.v2selectedOpdracht.opdrachtId;

            v2localCache.upsertWerkbonForm(opdrachtId, form.value);

            console.log('opdrachtId', opdrachtId);
    
            const response = await v2putWerkbonForm(opdrachtId, form.value);
            if(form.value.handtekening)
            prepareBase64ExternalObject(form.value.handtekening, opdrachtId);

            console.log('response', response);
    
            if (!response?.includes('NOK')) {
                const order = await getFullOrder(opdrachtId);
                await v2localCache.updateLocalOpdracht(opdrachtId, order);

                const apiStore = useApiStore();
                console.log('opdrachtId', opdrachtId);
                apiStore.v2loadOpdracht(opdrachtId);

                modalOpen.value = false;
                console.log('success');
            } else {
                //errors.value.general = response[0];
                console.log('error', errors.value);
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            processing.value = false;
        }
    }    

    const loadSignature = async (opdrachtId) => signature.value = await getSignature(opdrachtId);

    return { 
        modalOpen,
        form,
        errors,
        opdrachtId, 
        cancelSluiten, 
        handtekeningModalOpen, 
        cachedOpdrachtStatus, 
        loadSignature, 
        signature, 
        processing, 
        v2opslaanSluiten,
        v2loadWerkbonForm,
        v2opslaanVerzenden
    }
})
