<script setup>
import { computed, onMounted } from 'vue'
import AppLayout from '../layouts/AppLayout.vue'
import AgendaItems from '../components/AgendaItems.vue'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import { useDateStore } from '@/stores/dateStore';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/solid';
import { useAgendaStore } from '@/stores/agendaStore';
import { useApiStore } from '@/stores/apiStore';
import { testFunction } from '@/services/api-service';
import { useTranslationStore } from '@/stores/translationStore';

const agendaStore = useAgendaStore()
const dateStore = useDateStore()
const apiStore = useApiStore()

const translationStore = useTranslationStore()

apiStore.v2loadAgendaItems();
const _v2agendaItems = computed(() => apiStore.v2agendaItems.filter(item => item.datumUitvoering.startsWith(dateStore.apiFormattedDate)))
//const _v2agendaItems = computed(() => apiStore.v2agendaItems.filter(item => item.datumUitvoering.startsWith(dateStore.fakeDate)))
onMounted(() => {
    testFunction();
})
</script>

<template>
  <ReloadPrompt />
  <AppLayout>
    <template #title>{{ translationStore.t('agenda.titel') }}</template>
    <template #middle>
        <button class="maxi-button text-xl  font-medium" :class="[dateStore.canDecrement ? '' : 'invisible ']" @click="dateStore.decrement()">
            <MinusSmallIcon class="h-5 w-5 text-white" aria-hidden="true"></MinusSmallIcon>
        </button>
        <div class="bg-mc-secondary flex items-center rounded px-12 py-2 text-xl">{{ dateStore.formattedDate }}</div>
        <button class="maxi-button text-xl font-medium" :class="[dateStore.canIncrement ? '' : 'invisible ']" @click="dateStore.increment()">
            <PlusSmallIcon class="h-5 w-5 text-white" aria-hidden="true"></PlusSmallIcon>
        </button>
    </template>
    <div class="grid grid-cols-8 gap-4">
      <div class="col-span-5 text-sm">
        <h1 class="text-2xl font-bold leading-tight tracking-tight text-gray-900 pb-4">
            {{ translationStore.t('agenda.welkom', {user: apiStore.authenticatedUser?.name}) }}
        </h1>
        <AgendaItems :items="_v2agendaItems" />
      </div>
      <div class="col-span-3">
        <h1 class="text-2xl font-bold leading-tight tracking-tight text-gray-900 pb-4">
          {{ translationStore.t('agenda.planning_opmerkingen') }}
        </h1>
        <div class="flex flex-col gap-2 text-sm">
          <div v-if="agendaStore.selected?.agendaAlgemeen" class="bg-mc-primary p-4 text-white rounded-md">
            <h3 class="font-bold">{{ translationStore.t('agenda.planning_opmerking.opmerking') }} Algemeen</h3>
            <p>{{ agendaStore.selected?.agendaAlgemeen }}</p>
          </div>
          <div v-if="agendaStore.selected?.agendaWagen" class="bg-mc-primary p-4 text-white rounded-md">
            <h3 class="font-bold">{{ translationStore.t('agenda.planning_opmerking.opmerking') }} {{ agendaStore.selected?.typeWagen }}</h3>
            <p>{{ agendaStore.selected?.agendaWagen }}</p>
          </div>
          <div v-if="agendaStore.selected?.agendaBestuurder" class="bg-mc-primary p-4 text-white rounded-md">
            <h3 class="font-bold">{{ translationStore.t('agenda.planning_opmerking.opmerking') }} {{ agendaStore.selected?.bestuurder }}</h3>
            <p>{{ agendaStore.selected?.agendaBestuurder }}</p>
          </div>
          <div v-if="agendaStore.selected?.agendaBegeleider" class="bg-mc-primary p-4 text-white rounded-md">
            <h3 class="font-bold">{{ translationStore.t('agenda.planning_opmerking.opmerking') }} {{ agendaStore.selected?.begeleider }}</h3>
            <p>{{ agendaStore.selected?.agendaBegeleider }}</p>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
