<script setup>
import { onMounted, reactive } from 'vue';
import { login } from '@/services/auth-service';
import { useApiStore } from '@/stores/apiStore';
import VersionIndicator from '@/components/VersionIndicator.vue';

    const apiStore = useApiStore();
    
    const form = reactive({
        werknemerId: '',
        wachtwoord: ''
    })

    onMounted(() => {
        //check if user exists in localstorage if it exists go to /
        //if not stay on login page
        if(localStorage.getItem('user')) {
            window.location.href = '/';
            apiStore.authenticated = true;
            apiStore.authenticatedUser = JSON.parse(localStorage.getItem('user'));
        }
    })

    const submit = async () => {
        console.log('logging in');
        const user = await login(form.werknemerId, form.wachtwoord)
        console.log(user);
        if(user) {
            window.location.href = '/';
            apiStore.authenticated = true;
            apiStore.authenticatedUser = JSON.parse(localStorage.getItem('user'));
        }
    }
</script>

<template>
    <VersionIndicator />
    <div class="flex items-center justify-center h-screen w-screen">
        <img src="/maxi_bg.jpg" alt="" class="h-screen w-screen object-cover absolute top-0 left-0">
        <div class="absolute p-4 z-10 flex flex-col items-center gap-8">
            <div>
                <img src="/maxi_logo.png" alt="">
            </div>
            <div class="bg-mc-primary rounded py-12 px-8 flex flex-col gap-4 w-[40vw]">

                <div class="text-white text-center flex flex-col gap-2">
                    <h1 class="text-5xl font-medium">Login</h1>
                    <h2 class="text-xl">RADIS MOBILE APP</h2>
                </div>
                <div class="grid gap-2">
                    <label for="" class="text-white">Werknemer ID</label>
                    <input type="text" placeholder="Werknemer ID" class="rounded p-2 w-full" v-model="form.werknemerId">
                </div>
                <div class="grid gap-2">
                    <label for="" class="text-white">Wachtwoord</label>
                    <input type="password" placeholder="Wachtwoord" class="rounded p-2 w-full" v-model="form.wachtwoord">
                </div>
                <button @click="submit" class="bg-mc-tertiary text-white p-2 rounded w-full">
                    Aanmelden
                </button>
            </div>
        </div>
    </div>
</template>
